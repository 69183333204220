import React from "react";
import { useNavigate } from "react-router-dom";
import HeroBanner from "../../Components/HeroBanner";
import OurServices from "../../sections/OurServices";
import { OurServicesData } from "../../constants/services";
import { useIsMobile } from "../../Utilities/Utility";
import DropDown from "../../Components/DropDown";
import TabSwitcher from "../../Components/Tabs/TabSwitcher";
import { AboutUsTabData, OurMissionData, OurParentData } from "../../constants/aboutUs";
import { scrollToSpecificPath } from "../../Utilities/Utility";
import WhoWeAre from "../../sections/WhoWeAre";
import { AboutUsData, WhoWeAreData, WhyChooseUsData } from "../../constants/aboutUs";
import OurParentAndMission from '../../sections/OurParentAndMission';
import WhyChooseUs from '../../sections/WhyChooseUs';

const AboutPage = () => {
  const isMobileDevice = useIsMobile();
  const navigate = useNavigate();

  const handleTabChange = (path) => {
    navigate(`/about-us#${path}`);
    scrollToSpecificPath(path);
  };

  return (
    <>
      <HeroBanner contentData={AboutUsData} isMobileDevice={isMobileDevice} />
      {isMobileDevice ? 
        <DropDown contentData={AboutUsTabData} onTabChange={handleTabChange} />
       : (
        <TabSwitcher
          contentData={AboutUsTabData}
          isMobileDevice={isMobileDevice}
          onTabChange={handleTabChange}
        />
      )}
      <OurParentAndMission contentData={OurMissionData} />
      <WhoWeAre contentData={WhoWeAreData} />
      <WhyChooseUs contentData={WhyChooseUsData} />
      <OurServices contentData={OurServicesData} />
      <OurParentAndMission contentData={OurParentData} />
    </>
  );
};

export default AboutPage;
