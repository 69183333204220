import React from "react";
import "../stylesheets/rds-franchise-contact-booking-tray.css";
import "../stylesheets/rds-bootstrap.css";
import { getTrayStyle, getTextStyle } from "../utility";
import Rdsbtn from "./rds-btn";

class RdsFranchiseContactBookingTray extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerFranchisePhone: null,
      franchiseDetails: {},
    };
  }

  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  componentDidMount() {
    if (!this.state.headerFranchisePhone) {
      const franchiseDetailsString = localStorage.getItem("FRANCHISE_DETAILS");
      if (franchiseDetailsString) {
        const franchiseDetails = JSON.parse(franchiseDetailsString);
        const headerFranchisePhone =
          franchiseDetails?.franchiseDetails?.headerFranchisePhone;
        if (headerFranchisePhone) {
          this.setState({ headerFranchisePhone });
        }
        if (franchiseDetails) {
          this.setState({ franchiseDetails });
        }
      }
    }
  }

  render() {
    const {
      backgroundColor,
      traySectionHeading,
      heading,
      subHeading,
      anchorTagValue,
    } = this.props;

    const trayStyle = getTrayStyle(backgroundColor);
    const textStyle = getTextStyle(backgroundColor);
    const { headerFranchisePhone, franchiseDetails } = this.state;
    const renderRdsBtn = !headerFranchisePhone ? null : (
      <Rdsbtn
        tel={headerFranchisePhone}
        text={`CALL NOW: ${headerFranchisePhone}`}
        buttonClass="rds rds-btn secondary phone-icon"
        franchiseDetails={franchiseDetails?.franchiseDetails}
      />
    );

    return (
      <>
        <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
          <div className="container pt-4 pb-4">
            <div className="row pb-3">
              {traySectionHeading && (
                <h2 className={`rds-section-heading pb-2`} style={textStyle}>
                  {traySectionHeading}
                </h2>
              )}
              {heading && (
                <h3 className={`rds-heading pb-2`} style={textStyle}>
                  {heading}
                </h3>
              )}
              {subHeading && (
                <h4 className={`rds-subHeading`} style={textStyle}>
                  {subHeading}
                </h4>
              )}
            </div>
            <div className="d-flex justify-content-center">{renderRdsBtn}</div>
          </div>
        </div>
      </>
    );
  }
}

export default RdsFranchiseContactBookingTray;
