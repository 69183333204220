import React from "react";
import { Link } from "react-router-dom";

import styles from "./CopyRight.module.css";

const YourPrivacyConent = ({ contentData = {} }) => {
  const { privacyChoiceText, privacyChoiceImg, alt, width, height, url, target } = contentData;
  return (
    <div className={styles.privacyContainer}>
      <span className={styles.separator}>{"|"}</span><Link to={url} target={target}>
        {privacyChoiceText}
        <img
          src={privacyChoiceImg}
          alt={alt}
          width={width}
          height={height}
        />
      </Link>
    </div>
  )
}

const CopyRight = ({ copyRightContent = {}, privacyChoiceContent = {} }) => {

  return (
    <div className={styles.copyRightContainer}>
      <span>{copyRightContent.copyRight}</span>
      <YourPrivacyConent contentData={privacyChoiceContent} />
    </div>
  );
};

export default CopyRight;
