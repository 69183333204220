import React from "react";
import StepsPanel from "./StepsPanel";
import styles from "./HowItWorks.module.css";
import FullTextBanner from "../../Components/FullTextBanner";
import {useIsMobile} from "../../Utilities/Utility";

const WhoWeAre = ({ contentData = {} }) => {
  const isMobile = useIsMobile();
  const { WhoWeAreSection = {}, WhoWeAreContents = [] } = contentData;
  //bootstap class combined in one string
  const bsClasses = "col-12 d-flex justify-content-center";
  return (
    <>
    <div id={WhoWeAreSection?.tabId} className="container mb-5">
      <div className="row mt-5 mb-3">
        <div className={`${bsClasses}`}>
          <h2 className={`${isMobile ? 'text-center': ''} ${styles.heading}`}>{WhoWeAreSection.title}</h2>
        </div>
        <div className={`${bsClasses}`}>
          <h3 className={`${isMobile ? 'text-center': ''} ${styles.subHeading}`}>{WhoWeAreSection.mainHeading}</h3>
        </div>
      </div>
      <StepsPanel contentData={WhoWeAreContents} />
    </div>
    <FullTextBanner contentData={WhoWeAreSection}/>
    </>
  );
};

export default WhoWeAre;
