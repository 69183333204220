const WE_MAKE_IT_EASY = "We make it easy";
const HOW_IT_WORKS = "How It Works";
const HOW_IT_WORKS_DESC =
  "Find the local professionals you can trust to keep your home in top condition.";

export const HowItWorksData = {
  HowItWorksSection: {
    title: WE_MAKE_IT_EASY,
    mainHeading: HOW_IT_WORKS,
    description: HOW_IT_WORKS_DESC,
  },
  StepsOfHowItWorks: [
    {
      step: "1",
      cardTitle: "Start Here",
      cardDesc: "Find an Ace Hardware Home Services location near you.",
    },
    {
      step: "2",
      cardTitle: "Contact Us",
      cardDesc: "Tell us about your project or problem & book service.",
    },
    {
      step: "3",
      cardTitle: "Sit back and relax",
      cardDesc:
        "Enjoy the security of knowing that Ace Hardware Home services trusted craftsmen is completing your job.",
    },
  ],
};
