import React from "react";
import { Link } from "react-router-dom";

import FaChevronRight from "../../../../SvgIconComponents/Arrows/FaChevronRight";
import styles from "./MenuList.module.css";
import { getFranchiseLocalStorage } from "../../../../../Utilities/Utility";
import { dataPush } from "../../../../../Utilities/GAnalytics";
import { JOB_APPLICATION_CLICK } from "../../../../../constants/gaTrack";

const MenuList = ({ data, hasSubMenu, handleClose, handleNextArrow }) => {
  const franchiseDetails = getFranchiseLocalStorage()?.franchiseDetails;
  const location = window.location.href;

  const handleWindowTracker = (e, franchiseDetails) => {
    e.stopPropagation();
    dataPush(
      JOB_APPLICATION_CLICK,
      franchiseDetails?.headerFranchiseName,
      franchiseDetails?.headerFranchiseAddress,
      location
    );
  };

  return (
    <li className={styles.menuListItem}>
      <div className={styles.menuElementRow}>
        <Link
          to={data?.redirectLink}
          onClick={(e) => {
            handleClose();
            if (data?.menuText === "Careers") {
              handleWindowTracker(e, franchiseDetails);
            }
          }}
          target={data?.target}
          rel={data?.target === "_blank" ? "noopener noreferrer" : null}
          className={styles.menuElement}
        >
          {data?.menuText}
        </Link>
        {hasSubMenu && hasSubMenu?.length > 0 && (
          <button
            className={`${styles.nextBtn} rounded-circle border-0 bg-transparent`}
            onClick={() => handleNextArrow(data.menuText)}
          >
            <FaChevronRight fillColor={"#858585"} />
          </button>
        )}
      </div>
    </li>
  );
};

export default MenuList;
