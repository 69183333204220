import React, { useEffect, useState } from "react";
import styles from "./offersTermsAndConditions.module.css";
import DynamicTrays from "../../Components/DynamicTrays";
import { useSelector, useDispatch } from "react-redux";
import { fetchOffersTermsAndConditions } from "../../redux-toolkit/slice/OffersTermsAndConditions/OffersTermsAndConditionsSlice";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";

const OffersTermsAndConditions = () => {
  const {
    data = {},
    isError,
    statusCode,
  } = useSelector((state) => state.OffersTermsAndConditions);
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    dispatch(fetchOffersTermsAndConditions());
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);
  

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div id={styles["termsAndConditionsTitle"]}>
              <h1>{data?.pageHeading}</h1>
            </div>
          </div>
        </div>
      </div>
      {data?.trays?.map((tray, index) => {
        return <DynamicTrays {...tray} key={index} />;
      })}

      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default OffersTermsAndConditions;
