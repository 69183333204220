import DesktopAboutUsHeroImg from "../assets/aboutUs/desktop/About_Us_New_Hero.png";
import MobileAboutUsHeroImg from "../assets/aboutUs/mobile/About_Us_New_Hero.png";

import UpfrontImage from "../assets/aboutUs/desktop/helpful-service-upfront.png";
import UprightImage from "../assets/aboutUs/desktop/helpful-service-upright.png";
import UpliftImage from "../assets/aboutUs/desktop/helpful-service-uplift.png";
import OurParentCompanyImage from "../assets/aboutUs/desktop/our-parent-company.png";
import OurMissionImage from "../assets/aboutUs/desktop/our-mission.png";
import WhyChooseUs from "../assets/aboutUs/desktop/why-choose-us.png";

/* About us Data */

export const AboutUsData = {
  title: "About Us",
  subTitle: "Ace Hardware has been serving neighbors throughout America for 100 years. Ace identified a need for a better solution for homeowners to get help with home projects and launched Ace Hardware Home Services. Ace is now Bringing Helpful to Your Home℠.",
  description:'',
  linkText: "",
  url: "",
  bannerImage: DesktopAboutUsHeroImg,
  bannerImageMob: MobileAboutUsHeroImg,
  showArrow: true,
  linkColor: "#fff",
};

export const AboutUsTabData = [
    { label: "Our Mission", value: "our-mission" },
    { label: "Who We Are", value: "who-we-are" },
    { label: "Why Choose Us", value: "why-choose-us" },
    { label: "What We Do", value: "what-we-do" },
    { label: "Our Parent Company", value: "our-parent-company" },
  ];

const WHO_WE_ARE = "Who We Are";
const HELPFUL_SERVICE = "Helpful Service Makes Us Different";
const WHO_WE_ARE_BORDER_HEADING =
  "“We believe that a job is not completed until our customer is completely satisfied, and we pledge to make it right no matter what. You can expect excellence every time – that is the Red Bow Promise”";
const WHO_WE_ARE_BORDER_DESCRIPTION =
  "- Nate Kukla | CEO Ace Hardware Home Services";


  
export const WhoWeAreData = {
  WhoWeAreSection: {
    title: WHO_WE_ARE,
    mainHeading: HELPFUL_SERVICE,
    whoWeAreBorderHeading: WHO_WE_ARE_BORDER_HEADING,
    whoWeAreBorderDescription: WHO_WE_ARE_BORDER_DESCRIPTION,
    tabId :"who-we-are"
  },
  WhoWeAreContents : [
    {
      whoWeAreImg: UpfrontImage,
      cardHeader: "We Are",
      cardTitle: "Upfront",
      cardDesc:
        "We are dedicated to delivering the highest quality. Our excellence honors the Ace name and inspires people.",
    },
    {
      whoWeAreImg: UprightImage,
      cardHeader: "We Are",
      cardTitle: "Upright",
      cardDesc:
        "Our service is so good, we stand behind all the work that we perform.",
    },
    {
      whoWeAreImg: UpliftImage,
      cardHeader: "We Always",
      cardTitle: "Uplift",
      cardDesc:
        "We strive to be your trusted provider of home preservation services, helping to repair, maintain, and rejuvenate the homes of our customers.",
    },
  ]
};

export const OurMissionData =  [
{
  header: "Our Mission",
  title: "Our Mission",
  titleDescription:
  "We offer a wide range of residential maintenance and home repair services including plumbing, heating, cooling, electrical, handyman and painting with the signature Ace Helpful service our customers know. Helpful is what we do – and Ace is on a Mission to be the best, most trusted provider of home preservation services.",
  linkText: "",
  linkUrl: "",
  showArrow: false,
  image: OurMissionImage,
  tabId: "our-mission",
  division: 'right',
  altImgText:"our-mission",
  background: "var(--neutral-050, #F7F7F7)"
}
];

export const OurParentData =  [
  {
  header: "Our Parent Company",
  title: "Ace Hardware",
  titleDescription:
    "Ace Hardware Home Services, Inc. is a subsidiary by Ace Hardware Corporation, the world’s largest retailer-owned cooperative, established in 1924.",
  linkText: "Go to Ace Hardware",
  linkUrl: "https://www.acehardware.com/",
  linkTarget: "_blank",
  showArrow: true,
  linkColor: "#D40029",
  image: OurParentCompanyImage,
  tabId: "our-parent-company",
  division: 'left',
  altImgText:"our-parent-company",
  background: "#0000",
  backgroundImage: true
}
];

export const WhyChooseUsData = {
  tabId: "why-choose-us",
  imageSrc : WhyChooseUs,
  altImgText: "An image grid of smiling Ace Hardware Home Services professionals.",
  title: "Why Choose Us",
  heading: "Choose Helpful For All Your Home Needs.",
  steps: [
  {
    title: "A Trusted Partner",
    content: "We uphold the Ace mission of conducting business with a servant heart. We will listen to your needs and deliver exceptional results with the friendly, helpful service you expect."
  },
  {
    title: "Professional",
    content: "From the moment you first contact us, we are knowledgeable and professional. We treat your home as if it was our own, and our goal is to exceed your expectations."
  },
  {
    title: "Trustworthy",
    content: "All our craftsmen have been chosen to uphold our standards and deliver service worthy of the Ace name."
  },
  {
    title: "Solution Oriented",
    content: "Excellent communication is important. We want you to feel informed during every step of the process. We will provide you with options and a clear path to success."
  },
  {
    title: "Time Saving",
    content: "We strive to be efficient, from timely quotes to meeting deadlines for full project completion. Our goal is to make the process easy, with results you’ll be proud of."
  },
  {
    title: "Quality Results",
    content: "In any interaction with Ace Hardware Home Services, from a simple quote to a large project, we strive to deliver a consistent experience of the highest quality."
  }
]
};
