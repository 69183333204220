import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useIsMobile, getFranchiseDataFromLS, useIsTab } from "../../Utilities/Utility";
import styles from "./BreadCrumb.module.css";

const BreadCrumb = () => {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const [marginTopClass, setMarginTopClass] = useState("");
  const location = useLocation();
  const [isFranchiseRibbonPresent, setIsFranchiseRibbonPresent] =
    useState(null);

  useEffect(() => {
    // Franchise Black Strap Ribbon
    const franchiseRibbonTimeout = setTimeout(() => {
      setIsFranchiseRibbonPresent(
        getFranchiseDataFromLS("recentFranchisePath")
      );
    }, 2000);
    // Secondary Nav Without Franchise Black Strap
    const secondaryNavTimeout = setTimeout(()=>{
      const secondaryNavigation = document.querySelector('#secondary-navigation');
      if(Boolean(secondaryNavigation&&!isFranchiseRibbonPresent)){
        setMarginTopClass(styles.bcMarginTopForSecondaryNav)
      }
    },200);
  
    return () =>{
      clearTimeout(franchiseRibbonTimeout);
      clearTimeout(secondaryNavTimeout);
    }
  }, []);

  useEffect(() => {
    const recentFranchiseReadinterval = setInterval(() => {
      if (isFranchiseRibbonPresent) {
        setMarginTopClass(styles.bcMarginTop)
        clearInterval(recentFranchiseReadinterval);
      } else if (location?.pathname?.includes("offices")) {
        const recentPath = getFranchiseDataFromLS("recentFranchisePath");
        if (recentPath) {
          setIsFranchiseRibbonPresent(recentPath);
          clearInterval(recentFranchiseReadinterval);
        }
        else{
          setIsFranchiseRibbonPresent(false);
        }
      }
    }, 100);
  }, [location, isFranchiseRibbonPresent]);

  useEffect(() => {
    const isExternalFranchiseStripPresent = Boolean(localStorage.getItem('ExtrenalFranchiseStrip'));
    if (isFranchiseRibbonPresent && isMobile) {
      setMarginTopClass(styles.bcMarginTopMob);
    } else if (isFranchiseRibbonPresent) {
      setMarginTopClass(styles.bcMarginTop);
    } 
    else if(isExternalFranchiseStripPresent && isTab){
      setMarginTopClass(styles.bcMarginTopTab);
    }
  
  }, [isFranchiseRibbonPresent, isMobile,location]); 


  const getSegmentName = (segment, index) => {
    if (segment === "service-details") {
      return "";
    } else if (segment === "hvac") {
      return "HVAC";
    } else if (segment === "faq") {
      return "FAQ";
    } else if (index === 1 && pathSegments[0] === "offices") {
      return (
        "Ace Hardware Home Services " +
        segment
          .replaceAll("-", " ")
          .replaceAll(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
          .replaceAll(/\bhvac\b/gi,'HVAC')
      );
    } else {
      return segment
        .replaceAll("-", " ")
        .replaceAll(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    }
  };

  const getSegmentLink = (segment, pathSegments, index) => {
    if (segment === "offices") {
      return "/hvac/service-locations";
    } else {
      return "/" + pathSegments.slice(0, index + 1).join("/");
    }
  };

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");

  if (!pathSegments?.length && !Boolean(isFranchiseRibbonPresent)) return null;

  return (
    <div
      className={`${
        location.pathname !== "/" ? styles.bcBlockSeparator : ""
      } ${marginTopClass}`}
    >
      <div className="container">
        {location.pathname !== "/" && (
          <ul className={styles.bcContainer}>
            <>
              <li>
                <Link to="/">Ace Hardware Home Services</Link>
                <span className={styles.bcItemSeparator}>/</span>
              </li>

              {pathSegments.map((segment, index) => (
                <li key={index}>
                  {index === pathSegments.length - 1 ? (
                    <span>{getSegmentName(segment, index)}</span>
                  ) : (
                    <>
                      <Link to={getSegmentLink(segment, pathSegments, index)}>
                        {getSegmentName(segment, index)}
                      </Link>
                      {segment !== "service-details" && (
                        <span className={styles.bcItemSeparator}>/</span>
                      )}
                    </>
                  )}
                </li>
              ))}
            </>
          </ul>
        )}
      </div>
    </div>
  );
};

export default BreadCrumb;
