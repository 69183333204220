import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import MultiLevelMenu from "./MultiLevelMenu";
import LogoPanel from "./MultiLevelMenu/LogoPanel";
import FranchiseInfoPanel from "./MultiLevelMenu/FranchiseInfo";
import styles from "./MobileMenuSidePanel.module.css";

const MobileMenuSidePanel = ({
  handleClose,
  logo,
  headerMenu,
  headerCommonMenu,
  franchiseHeaderName,
  franchiseHeaderPhoneNumber,
  isLastVisitedFranchiseRibbonActive
}) => {

  const [activeMenus, setActiveMenus] = useState({
    menuItems: headerMenu,
    menuTitle: "",
  });

  const [menuHistory, setMenuHistory] = useState([
    {
      menuItems: headerMenu,
      menuTitle: "",
    },
  ]);

  const [isSubmenuActive, setSubMenuActive] = useState(false);
  const [isMainMenuActive, setIsMainMenuActive] = useState(true);

  useEffect(() => {
    if (menuHistory?.length > 1) {
      setSubMenuActive(true);
    } else {
      setSubMenuActive(false);
      setIsMainMenuActive(true);
    }
  }, [menuHistory]);

  const updateMenu = (currentMenu, subMenuFlag, mainMenuFlag) => {
    const { menuText, headerSubMenu } = currentMenu;
    setSubMenuActive(subMenuFlag);
    setIsMainMenuActive(mainMenuFlag);
    setMenuHistory((prevState) => {
      let currentState = [...prevState];
      currentState.push({
        menuTitle: menuText,
        menuItems: currentMenu?.headerSubMenu,
      });
      return currentState;
    });
    setActiveMenus({ menuTitle: menuText, menuItems: headerSubMenu });
  };

  const handleBackArrow = () => {
    const menuHistoryLen = menuHistory?.length;
    setMenuHistory((prevState) => {
      let currentState = [...prevState];
      if (menuHistoryLen > 1) {
        currentState = currentState.slice(0, currentState?.length - 1);
      }
      return currentState;
    });
    if (menuHistoryLen - 2 >= 0) {
      setActiveMenus({
        menuTitle: menuHistory[menuHistoryLen - 2]?.menuTitle,
        menuItems: menuHistory[menuHistoryLen - 2]?.menuItems,
      });
    }
  };

  //Using react-spting/web animation
  //Step 1 - setting the animation properties like left from -100% to 0%
  const overLayStyles = useSpring({
    from: {
      display: "none",
      opacity: 0,
    },
    display: "block",
    opacity: 1,
    config: { mass: 1, tension: 35, friction: 10 },
  });

  const [springs, api] = useSpring(() => ({
    from: { left: "-100%" },
    left: "0%",
    config: { mass: 1, tension: 35, friction: 10 },
  }));

  const handleClick = () => {
    api.start({
      from: {
        left: "0%",
        opacity: 1,
      },
      to: {
        left: "-100%",
        opacity: 0,
      },
    });
    setTimeout(() => {
      handleClose();
    }, 600);
  };

  return (
    <>
      {/* Step 2 - Overlay container, onClick handler closes the menu  */}
      <div
        className={styles.menuSidePanelContainer}
        style={{ ...overLayStyles }}
        onClick={handleClick}
      />
      {/* Step 3 - assigning the animated div component */}
      <animated.div className={styles.menuSidePanel} style={{ ...springs }}>
        <LogoPanel
          handleBackArrow={handleBackArrow}
          handleClick={handleClick}
          handleClose={handleClose}
          isSubmenuActive={isSubmenuActive}
          logo={logo}
        />
        {
          isLastVisitedFranchiseRibbonActive &&
          <FranchiseInfoPanel
            franchiseHeaderName={franchiseHeaderName}
            franchiseHeaderPhoneNumber={franchiseHeaderPhoneNumber}
          />
        }
        <MultiLevelMenu
          menuData={activeMenus}
          commonMenu={headerCommonMenu}
          handleClose={handleClose}
          updateMenu={updateMenu}
          isSubmenuActive={isSubmenuActive}
          isMainMenuActive={isMainMenuActive}
        />
      </animated.div>
    </>
  );
};

export default MobileMenuSidePanel;
