import React, { useState, useEffect, useRef } from "react";
import { useLocation, useMatch, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import NavBar from "./NavBar";
import SecondaryNavBar from "./SecondaryNavBar";
import MobileMenuSidePanel from "./MobileMenuSidePanel";
import HamBurgerMenu from "../SvgIconComponents/HamBurgerMenu/HamBurgerMenu";
import LastFranchiseVisitedRibbon from "./LastFranchiseVisitedRibbon";
import { HeaderContentData } from "../../constants/header";
import { fetchFranchiseHeader } from "../../redux-toolkit/slice/franchiseHeader/franchiseHeaderSlice";
import {
  useIsMobile,
  getFranchiseLocalStorage,
  setFranchiseLocalStorage,
  getFranchiseDataFromLS,
  setRecentFranchisePath,
} from "../../Utilities/Utility";
import styles from "./Header.module.css";
import Rdsbtn from "../../AceHomeServicesRds/src/components/rds-btn";
import { setNoIndexTag } from "../../Utilities/Utility";
import useFranchiseNameForExternalFranchise from "../../hooks/useFranchiseNameForExternalFranchise";

export const HeaderBookServiceCTA = ({
  isMobileCTA = false,
  handleCloseMenu,
}) => {
  const isMobile = useIsMobile();
  const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
  const isLastVisitedFranchiseRibbonActive =
    recentFranchisePath || window.location.pathname.includes("offices");
  return isLastVisitedFranchiseRibbonActive ? (
    <div
      className={`${styles.book_service_header_CTA} ${
        isMobileCTA ? styles.book_service_header_CTA_mobile : ""
      }`}
    >
      <Link
        to={`${recentFranchisePath}/book-service`}
        onClick={() => {
          if (isMobile) handleCloseMenu();
        }}
      >
        <Rdsbtn buttonClass="rds rds-btn primary" text="Book Service" />
      </Link>
    </div>
  ) : null;
};

const Header = () => {
  let history = useLocation();
  const match = useMatch("/offices/:franchiseName/*");
  const franchiseNameParam = match?.params?.franchiseName ?? "";
  const externalFranchiseNameParam = useFranchiseNameForExternalFranchise('officesource');
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const [franchiseData, setFranchiseData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const {
    logo = {},
    headerMenu = [],
    headerCommonMenu = [],
  } = HeaderContentData;

  const { data } = useSelector((state) => state.franchiseHeader);


  useEffect(() => {
    if (franchiseNameParam) {
      const payLoad = `?FranchiseName=${franchiseNameParam}`;
      dispatch(fetchFranchiseHeader(payLoad));
    } else if (externalFranchiseNameParam) {
      localStorage.setItem('ExtrenalFranchiseStrip',externalFranchiseNameParam);
      const payLoad = `?FranchiseName=${externalFranchiseNameParam}`;
      dispatch(fetchFranchiseHeader(payLoad));
    }
    setRecentFranchisePath();
  }, []);

  useEffect(() => {
    if (data) {
      setFranchiseData({ ...data });
      const franchise = { ...data };
      setFranchiseLocalStorage(franchise);
      setRecentFranchisePath();
    }
  }, [data]);

  useEffect(() => {
    const franchiseLocalStorageData = getFranchiseLocalStorage();
    if(!externalFranchiseNameParam){
      localStorage.setItem('ExtrenalFranchiseStrip','')
    }
    if (franchiseLocalStorageData) {
      setFranchiseData({ ...franchiseLocalStorageData });
    }
  }, [history]);

  const handleCloseMenu = () => setIsOpen(!isOpen);
  const franchiseDetails = JSON.parse(
    getFranchiseDataFromLS("FRANCHISE_DETAILS")
  );
  const lastVisitedFranchiseRibbon =
    document.getElementById("franchise-ribbon");
  const [isSecondaryNavigationActive, setIsSecondaryNavigationActive] =
    useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isNavActive = window.scrollY > 0;
      setIsSecondaryNavigationActive(isNavActive);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsSecondaryNavigationActive(
      franchiseDetails &&
        franchiseDetails?.headerMenu &&
        franchiseDetails?.headerMenu.length > 0
    );
  }, [franchiseDetails?.headerMenu]);

  const isLastVisitedFranchiseRibbonActive = getFranchiseDataFromLS(
    "recentFranchisePath"
  );

  const isNoIndexTag = setNoIndexTag();

  return (
    <>
      <Helmet>
        {isNoIndexTag && <meta name="robots" content="noindex" />}
      </Helmet>
      <header
        className={`position-relative ${styles.headerContainer} ${
          Boolean(!isSecondaryNavigationActive && lastVisitedFranchiseRibbon)
            ? styles.headerContainerMinHeight
            : ""
        }`}
        ref={headerRef}
      >
        <nav className="navbar fixed-top navbar-light bg-white p-0">
          <div className="container">
            <div className="col-1 d-block d-sm-block d-md-none d-lg-none">
              <button
                className={`border-0 ${styles.bgWTransparent}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <HamBurgerMenu />
              </button>
            </div>

            <div className={`col-11 col-md-6 d-flex ${styles.displayStyles}`}>
              <Link
                className={`navbar-brand p-0 ${
                  isMobile ? styles.logoCenterMob : ""
                }`}
                to={logo.url}
              >
                <img
                  src={logo.path}
                  alt={logo.alt}
                  className={styles.image}
                  width="142"
                  height="65"
                />
              </Link>
            </div>
            <div className="col-md-6 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
              <div className={styles.menuContainer}>
                <NavBar menuItems={headerMenu} handleClose={handleCloseMenu} />
                <HeaderBookServiceCTA handleCloseMenu={handleCloseMenu} />
              </div>
            </div>
          </div>
        </nav>

        {/* Last visited Franchise ribbon */}
        {isLastVisitedFranchiseRibbonActive && (
          <LastFranchiseVisitedRibbon
            headerRef={headerRef}
            logo={logo}
            headerMenu={franchiseData?.headerMenu}
            headerCommonMenu={headerCommonMenu}
            headerFranchiseCity={franchiseData?.franchiseDetails?.headerFranchiseCity}
            headerFranchiseStateAbbreviation={franchiseData?.franchiseDetails?.headerFranchiseStateAbbreviation}
            franchiseHeaderName={
              franchiseData?.franchiseDetails?.headerFranchiseName
            }
            franchiseHeaderPhoneNumber={
              franchiseData?.franchiseDetails?.headerFranchisePhone
            }
            isLastVisitedFranchiseRibbonActive={
              isLastVisitedFranchiseRibbonActive
            }
          />
        )}

        {
          /* Place for secondary NavBar */
          isSecondaryNavigationActive && (
            <SecondaryNavBar
              menuItems={franchiseData?.headerMenu}
              isLastVisitedFranchiseRibbonActive={
                isLastVisitedFranchiseRibbonActive
              }
              handleClose={handleCloseMenu}
              headerRef={headerRef}
            />
          )
        }

        {/* Mobile navigation menu panel */}
        <div className="position-relative">
          {isOpen && (
            <MobileMenuSidePanel
              logo={logo}
              headerMenu={headerMenu}
              headerCommonMenu={headerCommonMenu}
              handleClose={handleCloseMenu}
              franchiseHeaderName={
                franchiseData?.franchiseDetails?.headerFranchiseName
              }
              franchiseHeaderPhoneNumber={
                franchiseData?.franchiseDetails?.headerFranchisePhone
              }
              isLastVisitedFranchiseRibbonActive={
                isLastVisitedFranchiseRibbonActive
              }
            />
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
