import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getTrayStyle, getTextStyle } from "../utility";
import "../stylesheets/rds-bootstrap.css";
import styles from "../stylesheets/rds-image-gallery-tray.module.css";

class RdsImageGalleryTray extends Component {
  render() {
    const {
      images,
      sectionHeading,
      heading,
      backgroundColor,
      anchorTagValue,
      anchorTagClass,
      imagesPerRow,
    } = this.props;
    const trayStyle = getTrayStyle(backgroundColor);
    const textStyle = getTextStyle(backgroundColor);

    const imagesPerRowClassMapping = {
      2: "row-cols-md-2",
      3: "row-cols-md-3",
      4: "row-cols-md-4",
      5: "row-cols-md-4 row-cols-lg-5",
      6: "row-cols-md-4 row-cols-lg-6",
    };
    const imageRowContainerClass = {
      2: styles.gallery_two_image_row,
    };
    return (
      <div
        style={trayStyle}
        id={anchorTagValue}
        className={`${anchorTagClass} ${
          imageRowContainerClass[imagesPerRow]
            ? imageRowContainerClass[imagesPerRow]
            : ""
        }`}
      >
        <div className={`container ${styles.containerPadding}`}>
          {sectionHeading && (
            <h2 className="rds-section-heading" style={textStyle}>
              {sectionHeading}
            </h2>
          )}
          {heading && (
            <h3 className={`rds-heading ${styles.imagePadding}` }style={textStyle}>
              {heading}
            </h3>
          )}
          <div className={`row row-cols-xs-1 row-cols-sm-2 ${imagesPerRowClassMapping[imagesPerRow]} `}>
            {images.map((image, index) => (
              <div
                key={index + "in"}
                className={styles.imagePadding}
              >
                {image.redirectToUrl ? (
                  <Link
                    to={image.redirectToUrl}
                    target={image?.target ? image?.target : "_self"}
                    rel={
                      image?.target === "_blank" ? "noopener noreferrer" : null
                    }
                  >
                    <img
                      src={image.image}
                      alt={
                        image?.imageAltText
                          ? image?.imageAltText
                          : image.heading
                      }
                      title={
                        image?.imageAltText
                          ? image?.imageAltText
                          : image.heading
                      }
                      className={styles.image}
                    />
                  </Link>
                ) : (
                  <img
                    src={image.image}
                    alt={
                      image?.imageAltText ? image?.imageAltText : image.heading
                    }
                    title={
                      image?.imageAltText ? image?.imageAltText : image.heading
                    }
                    className={styles.image}
                  />
                )}
                <h3
                  className={`${styles.rdsImageGalleryTrayHeading} ${styles.subHeading}`}
                  style={textStyle}
                >
                  {image.subHeading}
                </h3>
                <div className={styles.caption}>
                  {image?.caption && (
                    <h4
                      className={`${styles.rdsImageGalleryTraySubHeading} ${styles.captionHeading}`}
                      style={textStyle}
                    >
                      {image.caption}
                    </h4>
                  )}
                  {image?.description && (
                    <p
                      className={`${styles.rdsImageGalleryTraydescription}`}
                      style={textStyle}
                    >
                      {image.description}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

RdsImageGalleryTray.defaultProps = {
  imagesPerRow: 4,
};

export default RdsImageGalleryTray;
