import React, { useEffect, useState } from "react";
import { getFranchiseDataFromLS } from "../../../Utilities/Utility";
import NavBar from "../NavBar";
import styles from "./SecondaryNavBar.module.css";

const SecondaryNavBar = ({
  menuItems,
  handleClose,
  headerRef,
  isLastVisitedFranchiseRibbonActive,
}) => {
  const [topPos, setTopPos] = useState(0);
  const [isFranchiseRibbonPresent, setIsFranchiseRibbonPresent] = useState(
    getFranchiseDataFromLS("recentFranchisePath")
  );

  useEffect(() => {
    //const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath")
    if (
      isLastVisitedFranchiseRibbonActive &&
      menuItems &&
      menuItems.length === 0
    ) {
      const element = document.getElementById("franchise-ribbon");
      const topValue = element.getBoundingClientRect().top;
      const height = element.offsetHeight;
      const menuTopValue = topValue + height - 4;
      setTopPos(menuTopValue);
    } else if (
      isLastVisitedFranchiseRibbonActive &&
      menuItems &&
      menuItems.length > 0
    ) {
      setTopPos("129");
    } else {
      if (headerRef.current) {
        const height = headerRef.current.getBoundingClientRect().height;
        setTopPos(height);
      }
    }
  }, [topPos, headerRef, isLastVisitedFranchiseRibbonActive]);

  return (
    <div
      style={{ top: `${topPos}px` }}
      className={styles.secondayNavBarContainer}
      id="secondary-navigation"
    >
      <div className="container">
        <NavBar menuItems={menuItems} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default SecondaryNavBar;
