import React from "react";
import "../stylesheets/rds-promotions-tray.css";
import { getTextStyle, getTrayStyle } from "../utility";
import { Markup } from "interweave";
import PropTypes from "prop-types";
import Rdsbtn from "../components/rds-btn";
import logo from "../assets/AceHomeServices.png";

export default function RdsPromotionsTray({
  offers,
  backgroundColor,
  sectionHeading,
  heading,
  subHeading,
}) {
  const callButtonProps = {
    text: "CALL FOR DETAILS",
    buttonClass: "rds rds-btn secondary custom-btn",
    iconClass: "fa-icon leading fa-regular fa-phone",
    iconType: "leading",
    disabled: false,
  };
  const printButtonProps = {
    text: "PRINT",
    buttonClass: "rds rds-btn primary",
    iconClass: "fa-icon leading fa-regular fa-print",
    iconType: "leading",
    disabled: false,
  };

  const print = () => {
    const rows = [];
    if (offers && offers.length > 0) {
      for (let i = 0; i < offers.length; i += 3) {
        const rowOffers = offers.slice(i, i + 3);
        const cols = rowOffers.map((offer, index) => (
          <div className="print-offer-class">
            <div className="print-offer">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={logo}
                  alt="Ace Home services"
                  width="300"
                  height="150"
                />
              </div>
              {offer.heading && (
                <div
                  className="heading"
                  style={{
                    fontSize: "34px",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: "25px",
                  }}
                >
                  {offer.heading}
                </div>
              )}
              {offer.summary && (
                <div
                  className="summary"
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    fontSize: "20px",
                  }}
                >
                  <Markup content={offer.summary} />
                </div>
              )}
              {offer.termsAndCondition && (
                <div
                  className="termsAndCondition"
                  style={{
                    textAlign: "center",
                    marginTop: "25px",
                    fontSize: "20px",
                  }}
                >
                  <Markup content={offer.termsAndCondition} />
                </div>
              )}
            </div>
          </div>
        ));
        rows.push(
          <div className="row justify-content-center rds-rows" key={i}>
            {cols}
          </div>
        );
      }
    }
    return rows;
  };

  const renderOffers = () => {
    const rows = [];
    if (offers && offers.length > 0) {
      for (let i = 0; i < offers.length; i += 3) {
        const rowOffers = offers.slice(i, i + 3);
        const cols = rowOffers.map((offer, index) => (
          <div
            className="col-md-4 mb-5 d-flex flex-column align-items-center rds-offer"
            key={index}
          >
            {offer.sectionHeading && (
              <div className="rds-offer-sectionHeading">
                {offer.sectionHeading}
              </div>
            )}
            {offer.heading && (
              <div className="rds-offer-heading">{offer.heading} </div>
            )}
            {offer.summary && (
              <div className="rds-offer-summary">
                <Markup content={offer.summary} />
              </div>
            )}
            {offer.termsAndCondition && (
              <div className="rds-offer-termsAndCondition">
                <Markup content={offer.termsAndCondition} />
              </div>
            )}
            <div className="rds-offer-button-container d-flex">
              <Rdsbtn
                className="rds-button-call"
                {...callButtonProps}
                companyDetails={offer?.heading}
              />
              <Rdsbtn
                className="rds-button-print"
                {...printButtonProps}
                index={index}
                companyDetails={offer?.heading}
              />
            </div>
          </div>
        ));
        rows.push(
          <div className="row justify-content-center rds-rows" key={i}>
            {cols}
          </div>
        );
      }
    }
    return rows;
  };

  const trayStyle = getTrayStyle(backgroundColor);
  const textStyle = getTextStyle(backgroundColor);

  return (
    <div className="rds-wrapper" style={trayStyle}>
      <div className="container rds-offers-container">
        {sectionHeading && (
          <h2 className="rds-section-heading" style={textStyle}>
            {sectionHeading}
          </h2>
        )}
        {heading && (
          <h3 className="rds-heading" style={textStyle}>
            {heading}
          </h3>
        )}
        {subHeading && (
          <h4 className="rds-subHeading" style={textStyle}>
            {subHeading}
          </h4>
        )}
        <div className="mb-4"></div>
        {renderOffers()}
        {print()}
      </div>
    </div>
  );
}

RdsPromotionsTray.propTypes = {
  sectionHeading: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      heading: PropTypes.string,
      summary: PropTypes.string,
      termsAndCondition: PropTypes.string,
    })
  ).isRequired,
};
