import React from "react";
import "../stylesheets/rds-buttons.css";
import { dataPush } from "../GAnalytics";
import {
  PHONE_CALL_USAGE,
  PHONE_CLICK,
  PHONE_NUMBER_CLICK,
  PRINT,
} from "../gaTrack";
import { getFranchiseDataFromLS } from "../utility";
import printJS from "print-js";

class Rdsbtn extends React.Component {
  printOffer = (offerIndex) => {
    const offerElements = document.getElementsByClassName("print-offer");
    if (offerElements[offerIndex]) {
      const contents = offerElements[offerIndex].innerHTML;

      // Check if the device is an iOS device
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (isIOS) {
        // Open the content in a new window or tab for manual printing
        const newWindow = window.open("", "_blank");
        newWindow.document.write(contents);
        newWindow.document.close();
        newWindow.focus();
        newWindow.print();

        // Attempt to close the new tab immediately after opening it for printing
        // Varies based on browser and iOS version
        setTimeout(() => {
          newWindow.close();
        }, 500); // Adjust the timeout as needed
      } else {
        //Not IOS => use leverage printJS
        printJS({
          printable: contents,
          type: "raw-html",
          style: "@page { size: auto;  margin: 0mm; } body { margin: 1.6cm; }",
          targetStyles: ["*"],
        });
      }
    } else {
      console.error("Offer not found");
    }
  };

  handleWindowTracker = (e, franchiseDetails, location, eventName) => {
    e.stopPropagation();

    if (eventName === undefined) {
      dataPush(
        PHONE_NUMBER_CLICK,
        franchiseDetails?.headerFranchiseName,
        franchiseDetails?.headerFranchiseAddress,
        location
      );
    } else {
      dataPush(
        eventName,
        franchiseDetails?.headerFranchiseName,
        franchiseDetails?.headerFranchiseAddress,
        location
      );
    }
  };

  handleWindowTrackerExternalLink = (
    e,
    companyDetails,
    franchiseDetails,
    location,
    print
  ) => {
    e.stopPropagation();

    if (companyDetails?.companyName) {
      const companyAddress =
        companyDetails?.city + ", " + companyDetails?.stateAbbreviation;

      dataPush(
        PHONE_CALL_USAGE,
        companyDetails?.companyName,
        companyAddress,
        companyDetails?.redirectUrl,
        companyDetails?.redirectUrl
      );
    } else {
      const data = franchiseDetails?.franchiseDetails;
      if (print === "") {
        dataPush(
          PHONE_CLICK,
          data?.headerFranchiseName,
          data?.headerFranchiseAddress,
          location,
          companyDetails
        );
      } else {
        dataPush(
          PRINT,
          data?.headerFranchiseName,
          data?.headerFranchiseAddress,
          location,
          companyDetails
        );
      }
    }
  };

  onClickHandler = (e, franchiseDetails, offerindex, recentFranchisePath) => {
    const location = window.location.href;
    if (this.props.loading) {
      e.currentTarget.classList.toggle("buttonLoading");
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    if (this.props.tel) {
      window.location.href = `tel:${this.props.tel}`;
    }
    //Todo Change this to text.contains("Print") later.
    if (this.props.text === "PRINT") {
      this.printOffer(offerindex);
    }
    if (this.props.text === "CALL FOR DETAILS") {
      window.location.href = `tel:${franchiseDetails?.franchiseDetails?.headerFranchisePhone}`;
    }
    if (this.props.franchiseDetails) {
      this.handleWindowTracker(e, this.props.franchiseDetails, location);
    }
    if (this.props.companyDetails) {
      const print = this.props.text === "PRINT" ? this.props.text : "";
      this.handleWindowTrackerExternalLink(
        e,
        this.props.companyDetails,
        franchiseDetails,
        location,
        print
      );
    }
    if (this.props.eventName) {
      const formLocation = window.location.origin + recentFranchisePath;
      this.handleWindowTracker(
        e,
        franchiseDetails?.franchiseDetails,
        formLocation,
        this.props.eventName
      );
    }
  };

  render() {
    let value;
    let isMobileSupport = "ontouchstart" in document.documentElement;
    let useOnClickOnMobile = this.props.useOnClickOnMobile;
    if (this.props.iconType === "leading") {
      value = (
        <React.Fragment>
          <i className={this.props.iconClass}></i>
          {this.props.text}
        </React.Fragment>
      );
    } else if (this.props.iconType === "trailing") {
      value = (
        <React.Fragment>
          {this.props.text}
          <i className={this.props.iconClass}></i>
        </React.Fragment>
      );
    } else {
      value = this.props.text;
    }

    const franchiseDetailsString = localStorage.getItem("FRANCHISE_DETAILS");
    const franchiseDetails = JSON.parse(franchiseDetailsString);
    const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");

    const offerindex = this.props.index !== undefined ? this.props.index : "";

    return isMobileSupport && useOnClickOnMobile ? (
      <button
        id={this.props.id}
        aria-label={this.props.ariaLabel}
        disabled={this.props.disabled}
        className={`${this.props.buttonClass} ${
          this.props.mobileWidth === 100 ? `rds-btn-full-width` : ""
        }`}
        onTouchStart={(e) =>
          this.onClickHandler(
            e,
            franchiseDetails,
            offerindex,
            recentFranchisePath
          )
        }
      >
        <span>{value}</span>
      </button>
    ) : (
      <button
        id={this.props.id}
        aria-label={this.props.ariaLabel}
        disabled={this.props.disabled}
        className={`${this.props.buttonClass} ${
          this.props.mobileWidth === 100 ? `rds-btn-full-width` : ""
        }`}
        onClick={(e) =>
          this.onClickHandler(
            e,
            franchiseDetails,
            offerindex,
            recentFranchisePath
          )
        }
      >
        <span>{value}</span>
      </button>
    );
  }
}

export default Rdsbtn;
