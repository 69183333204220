import React from "react";

import MenuItems from "../MenuItems";
import styles from "./NavBar.module.css";

const NavBar = ({ menuItems, handleClose }) => {

  const depthLevel = 0;

  return (
    <div className={styles.menuContainer}>
      <ul className={styles.menus}>
        {menuItems && menuItems?.map((menu, index) => {
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
              handleClose={handleClose}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default NavBar;
