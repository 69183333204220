import React from "react";
import { useTransition, animated } from "@react-spring/web";

import MenuList from "../MenuList";
import styles from "./SubMenu.module.css";

const SubMenu = ({ data, handleClose, handleNextArrow }) => {
  const { menuItems, menuTitle } = data;

  const transitions = useTransition(data, {
    from: { right: "-200%" },
    enter: { right: "-100%" },
    leave: { right: "-100%" },
  });

  return transitions((style) => (
    <animated.div className={styles.subMenuContainer} style={{ ...style }}>
      {menuTitle && <h2 className={styles.menuSectionHeading}>{menuTitle}</h2>}
      <ul className={styles.subMenuList}>
        {menuItems?.map((menu, index) => {
          return (
            <MenuList
              data={menu}
              hasSubMenu={menu?.headerSubMenu}
              handleClose={handleClose}
              handleNextArrow={() => handleNextArrow(menu.menuText)}
              key={index}
            />
          );
        })}
      </ul>
    </animated.div>
  ));
};

export default SubMenu;
