import React from "react";
import "../stylesheets/rds-franchise-home-tile-card.css";
import "../stylesheets/rds-bootstrap.css";
import { formatPhoneNumber } from "../utility";
import RdsTextLink from "./rds-textlink";
import Rating from "react-rating";
import starEmpty from "../assets/star-empty.png";
import starRed from "../assets/star-red.png";
import phoneIcon from "../assets/phone-icon.png";
import { Link } from "react-router-dom";
import { PHONE_NUMBER_CLICK } from "../gaTrack";
import { dataPush } from "../GAnalytics";
import ExternalSystemMessageTray from "./rds-external-system-message";

class RdsFranchiseHomeTileCardTray extends React.Component {
  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  handleWindowTracker = (e, franchiseDetails, location) => {
    e.stopPropagation();

    dataPush(
      PHONE_NUMBER_CLICK,
      franchiseDetails?.headerFranchiseName,
      franchiseDetails?.headerFranchiseAddress,
      location
    );
  };

  render() {
    const {
      backgroundColor,
      heading,
      subHeading,
      anchorTagValue,
      franchiseRating,
      franchiseTotalReviewCount,
      franchiseViewAllReviewsLink,
      franchiseWriteReviewLink,
      franchisePhoneNumber,
      franchiseLicense,
      isMobile,
      franchiseData,
      externalFranchiseMessage,
    } = this.props;

    const franchiseDetails = franchiseData?.franchiseDetails;
    const location = window.location.href;

    return (
      <>
        <div
          className="rds-wrapper1"
          style={{ backgroundImage: 'url("/White_Triangle.png")' }}
          id={anchorTagValue}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-8 col-xs-12">
                {heading && (
                  <h1
                    className={`rds-franchise-home-tile-card-tray-heading pb-2`}
                  >
                    {heading}
                  </h1>
                )}
                {subHeading && (
                  <h2
                    className={`rds-franchise-home-tile-card-tray-sub-heading`}
                  >
                    {subHeading}
                  </h2>
                )}
                {franchiseLicense && franchiseLicense !== "#" && (
                  <p className={`rds-franchise-home-tile-card-tray-license`}>
                    License {franchiseLicense}
                  </p>
                )}

                <div
                  className={`${
                    isMobile
                      ? "d-flex  align-items-center flex-column"
                      : "d-flex  align-items-center "
                  }`}
                >
                  <div
                    className={`${
                      isMobile ? "rds-rating-div" : "d-flex align-items-center"
                    }`}
                  >
                    {franchiseRating && (
                      <div className="rds-franchise-home-tile-card-rating-total-count">
                        {franchiseRating}
                      </div>
                    )}
                    <div className="rds-rating-star-div">
                      {franchiseRating && (
                        <Rating
                          placeholderRating={franchiseRating}
                          emptySymbol={
                            <img
                              alt="Empty Rating"
                              src={starEmpty}
                              className="rds-rating-icon"
                            />
                          }
                          placeholderSymbol={
                            <img
                              alt="Star Rating"
                              src={starRed}
                              className="rds-rating-icon"
                            />
                          }
                          readonly={true}
                        />
                      )}
                    </div>

                    {franchiseTotalReviewCount && (
                      <p className="m-0 rds-rating-total">
                        ({franchiseTotalReviewCount})
                      </p>
                    )}
                  </div>
                  <div
                    className={`${
                      isMobile
                        ? "rds-rating-div mb-4"
                        : "d-flex align-items-center"
                    }`}
                  >
                    <div className="mx-2">
                      <RdsTextLink
                        linkClass={"rds rds-review-link secondary small"}
                        text={"See All Reviews"}
                        link={franchiseViewAllReviewsLink}
                        target="_blank"
                      />
                    </div>
                    <div className="mx-2">
                      <RdsTextLink
                        linkClass={"rds rds-review-link primary small"}
                        text={"Write Reviews"}
                        link={franchiseWriteReviewLink}
                        target="_blank"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 d-flex marginTop">
                {franchisePhoneNumber && (
                  <div>
                    <div className="rds-franchise-home-tile-card-tray-call">
                      CALL TO SCHEDULE SERVICE:
                    </div>
                    <div className="rds-franchise-home-tile-card-tray-call-number">
                      <img alt="PhoneIcon" src={phoneIcon} className="mx-2" />
                      <Link
                        className="rds-franchise-home-tile-card-tray-call-number"
                        to={`tel:${franchisePhoneNumber}`}
                        onClick={(e) =>
                          this.handleWindowTracker(
                            e,
                            franchiseDetails,
                            location
                          )
                        }
                      >
                        {formatPhoneNumber(franchisePhoneNumber)}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {externalFranchiseMessage && (
          <ExternalSystemMessageTray
            externalFranchiseMessage={externalFranchiseMessage}
          />
        )}
      </>
    );
  }
}

export default RdsFranchiseHomeTileCardTray;
