import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./TabSwitcher.module.css";

const TabSwitcher = ({ contentData, onTabChange }) => {
  const location = useLocation();
  const hashPath = location?.hash?.split("#")?.[1];

  const [current, setCurrent] = useState(
    hashPath ? hashPath : contentData?.length ? contentData[0].value : ""
  );

  const handleTabChange = (data) => {
    onTabChange(data);
    setCurrent(data);
  };

  return (
    <div className={`${styles.tabSwitchContainer}`}>
      <div className={`${styles.tabSwitchContent}`}>
        <div className={`container`}>
          <div className={`${styles.tabItemRow}`}>
            {contentData?.map((it, i) => {
              return (
                <div
                  key={i}
                  className={`${styles.tabItems}`}
                  onClick={() => handleTabChange(it.value)}
                >
                  <span
                    className={
                      current === it.value ? styles.tabItemsSelected : ""
                    }
                  >
                    {it?.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabSwitcher;
