import React from "react";
import { Link } from "react-router-dom";

import { getTrayStyle } from "../utility";
import "../stylesheets/rds-bootstrap.css";
import "../stylesheets/rds-common.css";
import styles from "../stylesheets/rds-service-areas-tray.module.css";

const ServiceAreaList = ({ data , anchorTagValue}) => {
  return (
    <div className={styles.list} id={anchorTagValue}>
      {data?.map((area, index) => {
        return (
          <div key={index + "key"} className={styles.listItem}>
            <h2 className={`${styles.sectionHeading} text-start`}>
              {area.serviceAreaState}
            </h2>
            {area.serviceAreaCityDetails &&
              area.serviceAreaCityDetails.map((city, index) =>
                city && city.redirectUrl ? (
                  <div key={index + "item"}>
                    {city?.openInNewTab ? (
                      <a
                        href={city.redirectUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        {city.city}
                      </a>
                    ) : (
                      <Link
                        to={city.redirectUrl}
                        target="_self"
                        className={styles.link}
                      >
                        {city.city}
                      </Link>
                    )}
                  </div>
                ) : (
                  <div key={index + "item"} className={styles.linkDisabled}>
                    {city.city}
                  </div>
                )
              )}
          </div>
        );
      })}
    </div>
  );
};

const RdsServiceAreasTray = (props) => {
  const {
    heading,
    imageAlignment,
    imageUrl,
    sectionHeading,
    serviceAreaDetails = [],
    subHeading,
    trayDimension,
    backgroundColor = "#fff",
    renderAction,
    anchorTagValue = "",
    imageAltText,
  } = props;

  const trayStyle = getTrayStyle(backgroundColor);

  const rowReverse = imageAlignment === "Right" ? false : true;

  const imgRatio =
    trayDimension === "50-50"
      ? styles.fb50
      : trayDimension === "70-30"
      ? styles.fb30
      : styles.fb70;

  const copyRatio =
    trayDimension === "50-50"
      ? styles.fb50
      : trayDimension === "70-30"
      ? styles.fb70
      : styles.fb30;

  return (
    <div
      className={`${styles.rdsServiceAreasTray} pt-5 mb-4`}
      style={{ ...trayStyle }}
      id={anchorTagValue}
    >
      <div className="container">
        <div
          className={`${styles.rdsServiceAreasTrayContent} ${
            rowReverse ? styles.rowReverse : styles.rowDir
          }`}
        >
          <div className={`${styles.copyContainer} ${copyRatio}`}>
            {sectionHeading && (
              <h2 className={`${styles.rdsSectionHeading} rds-section-heading`}>{sectionHeading}</h2>
            )}
            {heading && <h3 className={`${styles.rdsHeading} rds-heading`}>{heading}</h3>}
            {subHeading && <h4 className={`${styles.rdsSubHeading} rds-subHeading`}>{subHeading}</h4>}
            <ServiceAreaList data={serviceAreaDetails} />
            <div className="mb-4">{renderAction}</div>
          </div>
          <div className={`${styles.imgContainer} ${imgRatio}`}>
            <img
              src={imageUrl}
              alt={imageAltText ? imageAltText : sectionHeading}
              title={imageAltText ? imageAltText : sectionHeading}
              className={styles.image}
              width="100"
              height="100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RdsServiceAreasTray;
