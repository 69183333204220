import React, { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import { HeaderBookServiceCTA } from "../../index"
import MenuList from "./MenuList";
import SubMenu from "./SubMenu";
import CommonMenu from "./CommonMenu";
import styles from "./MultiLevelMenu.module.css";

const MultiLevelMenu = ({
  menuData,
  commonMenu,
  handleClose,
  updateMenu,
  isSubmenuActive,
  isMainMenuActive
}) => {

  const [transition, setTransition] = useState(true);

  useEffect(() => {
    setTransition(!transition);
  }, [menuData]);

  const handleNextArrow = (menuLabel) => {
    const activeMenuObj = menuData?.menuItems?.filter(
      (menu) => menu.menuText.toUpperCase() === menuLabel.toUpperCase()
    )[0];

    updateMenu(activeMenuObj, true, false);
  };

  const mainMenuStyles = useSpring({
    position: transition ? "absolute" : "relative",
    left: isSubmenuActive ? "-100%" : "0%",
  });

  return (
    <animated.div
      className={styles.mainMenuContainer}
      style={{ ...mainMenuStyles }}
    >
      {isMainMenuActive && (
        <>
          <ul className={styles.menuList}>
            {menuData?.menuItems?.map((menu, index) => {
              return (
                <MenuList
                  data={menu}
                  handleClose={handleClose}
                  handleNextArrow={() => handleNextArrow(menu.menuText)}
                  hasSubMenu={menu.headerSubMenu}
                  key={index}
                />
              );
            })}
          </ul>
          <HeaderBookServiceCTA isMobileCTA={true} handleCloseMenu={handleClose} />
        </>
      )}
      {isSubmenuActive && (
        <SubMenu
          data={menuData}
          handleClose={handleClose}
          handleNextArrow={handleNextArrow}
        />
      )}
      {!isSubmenuActive && (
        <CommonMenu commonMenuData={commonMenu} handleClose={handleClose} />
      )}
    </animated.div>
  );
};

export default MultiLevelMenu;
