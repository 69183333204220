import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NetworkManager from "../../redux-toolkit/networkManager/networkConfig";
import { getFranchiseLocalStorage } from "../../Utilities/Utility";
import { fetchFranchiseBookingService } from "../../redux-toolkit/slice/franchiseBookingService/franchiseBookingServiceSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import { useIsMobile } from "../../Utilities/Utility";
import styles from "./franchiseBookingService.module.css";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";
import FranchiseExternalMessageTray from "../../AceHomeServicesRds/src/components/rds-external-system-message";


const FranchiseBookingService = () => {
  const { franchiseName } = useParams();
  const [formSuccess, setFormSuccess] = useState(false);
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseBookingService
  );
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState(false);
  const navigate = useNavigate();
  const externalFranchiseMessage =
  useDisplayMsgForExternalFranchise("officesource");

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?FranchiseName=${franchiseName}`;
    dispatch(fetchFranchiseBookingService(payLoad));
  }, [franchiseName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  useEffect(() => {
    if (formSuccess) {
      const pathNameList = window.location.pathname.split("/");
      navigate(
        `/${pathNameList[1]}/${pathNameList[2]}/book-service-confirmation`,
        { state: { data: data?.bookServiceConfirmationForm } }
      );
    }
  }, [formSuccess]);

  const handleSubmit = (userData) => {
    const franchiseLocalStorageData = getFranchiseLocalStorage();
    const franchiseData = {
      name:
        franchiseLocalStorageData?.franchiseDetails?.headerFranchiseName || "",
      phoneNumber:
        franchiseLocalStorageData?.franchiseDetails?.headerFranchisePhone || "",
      emailAddress:
        franchiseLocalStorageData?.franchiseDetails?.headerFranchiseEmail || "",
    };

    const formData = {
      formName: "BOOK_SERVICE",
      userData,
      franchiseData,
    };
    NetworkManager.franchiseSendEmail(formData).then((response) => {
      if (response.status === 200) {
        setFormSuccess(true);
      } else {
        setFormSubmissionError(true);
      }
    });
  };

  return (
    <>
      {
        Boolean(externalFranchiseMessage) && <FranchiseExternalMessageTray externalFranchiseMessage={externalFranchiseMessage} />
      }
      <div>
        <div
          className="rds-wrapper"
          style={{
            backgroundImage: "url('/Black_Triangle.png')",
            minHeight: "200px",
          }}
        ></div>
        <div className="container mb-5 pb-5">
          <div
            className={
              isMobile ? styles.form_container_mobile : styles.form_container
            }
          >
            {data?.bookServiceForm && !formSuccess && (
              <DynamicTrays
                {...data.bookServiceForm}
                onFormSubmission={handleSubmit}
                formSubmissionError={formSubmissionError}
              />
            )}
          </div>
          {isPageContainsError && <ErrorPage statusCode={statusCode} />}
        </div>
      </div>
    </>
  );
};

export default FranchiseBookingService;
