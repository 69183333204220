import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchLineOfBusinessServiceLocations } from "../../redux-toolkit/slice/lineOfBusinessServiceLocations/lineOfBusinessServiceLocationsSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";

const LineOfBusinessServiceLocations = () => {
  const { lobType } = useParams();
  const {
    data = {},
    isError,
    statusCode,
  } = useSelector((state) => state.lineOfBusinessServiceLocations);
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?lobtype=${lobType}/service-locations`;
    dispatch(fetchLineOfBusinessServiceLocations(payLoad));
  }, []);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <DynamicTrays
            {...tray}
            key={index}
            externalFranchiseMessage={externalFranchiseMessage}
          />
        );
      })}
      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default LineOfBusinessServiceLocations;
