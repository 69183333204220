export const trackAnalytics = (
  eventName,
  clickActionText,
  companyName,
  companyAddress
) => {
  window.dataLayer.push({
    event: eventName,
    button_name: clickActionText,
    company_name: companyName,
    company_address: companyAddress,
  });
};

export const dataPush = (eventName, companyName, companyAddress, location) => {
  let data = {};

  const companyDetails = {
    event: eventName,
    company_name: companyName,
    company_address: companyAddress,
    page_url: location,
  };

  const carrerDetails = {
    event: eventName,
    company_name: companyName,
    company_address: companyAddress,
    previous_page_url: location,
  };

  if (eventName === "job_application_click") {
    data = { ...carrerDetails };
  } else {
    data = { ...companyDetails };
  }

  window.dataLayer.push({ ...data });
};
