import React from "react";
import RdsBtn from "./rds-btn";
import RdsLink from "./rds-textlink";
import { getTrayStyle } from "../utility";
import "../stylesheets/rds-company-details-tray.css";
import LinkArrow from "./SvgIconComponents/Arrows/LinkArrow";
import { formatPhoneNumber } from "../utility";

const CompanyDetailsTray = (companyListing) => {
  const handleOnClick = (openInNewTab, redirectUrl) => {
    if (/^\d+$/.test(redirectUrl)) {
      window.location.href = `tel:${redirectUrl}`;
    } else if (typeof redirectUrl === "string") {
      if (openInNewTab) {
        window.open(redirectUrl, "_blank");
      } else {
        window.location.href = redirectUrl;
      }
    }
  };

  const arrowColor = "var(--color-gray-black)";

  const stateList = [];

  if (
    companyListing?.companyDetails &&
    companyListing?.companyDetails?.length > 0
  ) {
    for (var i = 0; i < companyListing.companyDetails.length; i++) {
      stateList.push(companyListing.companyDetails[i].state);
    }
  }

  return (
    <>
      <div className="company-details">
        <div className="container company-details-container">
          {companyListing?.companyDetails &&
            companyListing.companyDetails.length > 0 &&
            companyListing.companyDetails.map((service, index) => (
              <div className="centered">
                {service?.state !== stateList[index - 1] && (
                  <div className="service-state">{service?.state}</div>
                )}
                <div
                  className="service-block"
                  style={getTrayStyle(service?.backgroundColor)}
                >
                  {companyListing?.isMobile && (
                    <div className="service-city">{`${service?.city}, ${service?.stateAbbreviation}`}</div>
                  )}
                  <div className="left-section">
                    <div className="centered-image">
                      <a
                        href={service?.ctas[2]?.redirectUrl}
                        rel="noreferrer"
                        target={service?.openInNewTab ? "_blank" : "_self"}
                      >
                        <img
                          src={service?.companyLogo}
                          alt={
                            service?.altImageText
                              ? service?.altImageText
                              : service?.companyName
                          }
                        />
                      </a>
                    </div>
                  </div>

                  <div className="middle-section">
                    {!companyListing?.isMobile && (
                      <div className="service-city">{`${service?.city}, ${service?.stateAbbreviation}`}</div>
                    )}
                    <div className="company-name">
                      <a
                        href={service?.ctas[2]?.redirectUrl}
                        rel="noreferrer"
                        target={service?.openInNewTab ? "_blank" : "_self"}
                        className="business-name-url"
                      >
                        {service?.companyName}
                      </a>
                    </div>
                    <div className="phone-number">
                      {formatPhoneNumber(service?.phoneNumber)}
                    </div>
                  </div>
                  <div className="right-section">
                    <div className="btn-container">
                      <div className="first-btn">
                        <RdsBtn
                          text={service?.ctas[0]?.text}
                          tel={service?.ctas[0]?.redirectUrl}
                          buttonClass="rds rds-btn primary expanded"
                          companyDetails={service}
                        />
                      </div>
                      <div className="second-btn">
                        <RdsBtn
                          text="Book Online"
                          buttonClass="rds custom-btn rds-btn secondary expanded"
                          onClick={() =>
                            handleOnClick(
                              service?.ctas[1]?.openInNewTab,
                              service?.ctas[1]?.redirectUrl
                            )
                          }
                        />
                      </div>
                      <div className="last-btn">
                        <RdsLink
                          text="See More Details"
                          link={service?.ctas[2]?.redirectUrl}
                          className="cta"
                          linkClass="rds rds-text-link secondary large"
                          iconType="trailing"
                          target={
                            service?.ctas[2]?.openInNewTab ? "_blank" : "_self"
                          }
                        />
                        <LinkArrow linkColor={arrowColor} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsTray;
