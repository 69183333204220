import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenuSidePanel from "../MobileMenuSidePanel";
import FaChevronRight from "../../SvgIconComponents/Arrows/FaChevronRight";
import {
  formatPhoneNumber,
  getFranchiseDataFromLS,
  getFranchiseLocalStorage,
} from "../../../Utilities/Utility";
import Location from "../../../assets/common/location.svg";
import Phone from "../../../assets/common/phone.svg";
import styles from "./LastFranchiseVisitedRibbon.module.css";
import { PHONE_NUMBER_CLICK } from "../../../constants/gaTrack";
import { dataPush } from "../../../Utilities/GAnalytics";

const LastFranchiseVisitedRibbon = ({
  headerRef,
  logo,
  headerMenu,
  headerCommonMenu,
  franchiseHeaderName,
  headerFranchiseCity,
  headerFranchiseStateAbbreviation,
  franchiseHeaderPhoneNumber,
  isLastVisitedFranchiseRibbonActive,
}) => {
  const [topPos, setTopPos] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const updateTopPos = () => {
      if (
        isLastVisitedFranchiseRibbonActive &&
        headerMenu &&
        headerMenu.length === 0
      ) {
        setTopPos(65);
      }
      if (
        isLastVisitedFranchiseRibbonActive &&
        headerMenu &&
        headerMenu.length > 0
      ) {
        const height = headerRef.current.getBoundingClientRect().height;
        setTopPos(height);
      }
    };

    // Call the function after a delay of 2 seconds when the component mounts
    const timerId = setTimeout(updateTopPos, 2000);
    window.addEventListener("scroll", updateTopPos);
    return () => {
      window.removeEventListener("scroll", updateTopPos);
      clearTimeout(timerId);
    };
  }, [headerRef, isLastVisitedFranchiseRibbonActive, headerMenu]);

  const handleCloseMenu = () => setIsOpen(!isOpen);
  const franchisePath = getFranchiseDataFromLS("recentFranchisePath");
  const franchiseDetails = getFranchiseLocalStorage()?.franchiseDetails;
  const location = window.location.href;

  const handleWindowTracker = (e, franchiseDetails) => {
    e.stopPropagation();

    dataPush(
      PHONE_NUMBER_CLICK,
      franchiseDetails?.headerFranchiseName,
      franchiseDetails?.headerFranchiseAddress,
      location
    );
  };

  return (
    <>
      <div
        className={styles.lvFranchiseRibbonContainer}
        style={{ top: `${topPos}px` }}
        id="franchise-ribbon"
      >
        <div className="container">
          <div className={styles.lvFranchiseRibbonContent}>
            <div className={styles.group}>
              <img
                src={Location}
                alt="Franchise location"
                width="18"
                height="20"
                className={styles.ml5}
              />
              <Link to={`${franchisePath}`}>
                {`${franchiseHeaderName ? franchiseHeaderName.trim() : ""} - ${
                  headerFranchiseCity ? headerFranchiseCity.trim() : ""
                }, ${
                  headerFranchiseStateAbbreviation
                    ? headerFranchiseStateAbbreviation.trim()
                    : ""
                }`}
              </Link>
            </div>
            <div className={`${styles.group} ${styles.phoneGroup}`}>
              <img src={Phone} alt="Phone" width="21" height="20" />
              <a
                href={`tel:${franchiseHeaderPhoneNumber}`}
                onClick={(e) => handleWindowTracker(e, franchiseDetails)}
              >
                {formatPhoneNumber(franchiseHeaderPhoneNumber)}
              </a>
            </div>
            <button
              type="button"
              className={styles.menuToggler}
              onClick={() => setIsOpen(!isOpen)}
            >
              <FaChevronRight fillColor={"#fff"} />
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <MobileMenuSidePanel
          logo={logo}
          headerMenu={headerMenu}
          headerCommonMenu={headerCommonMenu}
          franchiseHeaderName={franchiseHeaderName}
          franchiseHeaderPhoneNumber={franchiseHeaderPhoneNumber}
          handleClose={handleCloseMenu}
          isLastVisitedFranchiseRibbonActive={
            isLastVisitedFranchiseRibbonActive
          }
        />
      )}
    </>
  );
};

export default LastFranchiseVisitedRibbon;
