import React from "react";
import ListOfServices from "./ServiceTypePanel";
import styles from "./OurServices.module.css";

const OurServices = ({ contentData = {} }) => {
  const { OurServicesSection = {}, ListOfOurServices = [] } = contentData;
  //bootstap class combined in one string
  const bsClasses = "col-12 d-flex justify-content-center";
  return (
    <div className={styles.bgImage}>
      <div id={OurServicesSection?.tabId} className="container">
        <div className="row mb-3">
          <div className={`${bsClasses} mt-4`}>
            <h2 className={styles.heading}>{OurServicesSection.title}</h2>
          </div>
          <div className={`${bsClasses}`}>
            <h3 className={styles.subHeading}>
              {OurServicesSection.mainHeading}
            </h3>
          </div>
          <div className={`${bsClasses}`}></div>
        </div>
        <ListOfServices contentData={ListOfOurServices} />
      </div>
    </div>
  );
};

export default OurServices;
