import React from "react";
import { Link } from "react-router-dom";

import FaChevronRight from "../../SvgIconComponents/Arrows/FaChevronRight";
import styles from "./FooterNavBar.module.css";

const FooterNavBar = ({ footerNavItems = [], footerTheme = "#fff" }) => {
  return (
    <ul className={`${styles.navbarContainer}`}>
      {footerNavItems.map((item, index) => (
        <li key={index} className={styles.navLinksFooter}>
          <Link
            to={item.uri}
            target={item?.target ? item?.target : "_self"}
            rel={`${item?.target ? "noopener noreferrer" : ""}`}>
            {item.name}
          </Link>
          <Link to={item.uri}><FaChevronRight fillColor={footerTheme} /></Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterNavBar;
