import React from "react";
import "../stylesheets/rds-form-complete.css";
import Rdsbtn from './rds-btn';

class RdsFormCompleteTray extends React.Component {

    onClickHandler = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };


    render() {
        const {
            sectionHeading,
            heading,
            subHeading,
            cta
        } = this.props;


        return (
            <>
                <div className="rds-wrapper">
                    <div className="container pt-4 pb-4">
                        <div className="row pb-3">
                            {sectionHeading && (
                                <h2
                                    className={`rds-section-heading pb-2`}
                                >
                                    {sectionHeading}
                                </h2>
                            )}
                            {heading && (
                                <h3
                                    className={`rds-heading`}
                                >
                                    {heading}
                                </h3>
                            )}
                            {subHeading && (
                                <h4
                                    className={`rds-subHeading`}
                                >
                                    {subHeading}
                                </h4>
                            )}

                            <div className="mt-3 d-flex justify-content-center">
                                <Rdsbtn 
                                    text={cta.text} 
                                    buttonClass='rds rds-btn primary'
                                    onClick={()=> window.location.href = cta.redirectUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default RdsFormCompleteTray;
