import React from "react";
import PropTypes from "prop-types";
import "../stylesheets/rds-hero-banner.css";
import ExternalSystemMessageTray from "./rds-external-system-message";

const RdsHeroBanner = ({bannerImage, bannerLogo, title, subTitle, description, externalFranchiseMessage}) => {

  return (
    <>
      {externalFranchiseMessage && (
        <ExternalSystemMessageTray
          externalFranchiseMessage={externalFranchiseMessage}
        />
      )}
      <div className="hero-banner-tray" style={
        { backgroundImage: `url(${bannerImage})`}
        }>

        <div className="hero-banner-background">
          <div className="container hero-banner-container d-flex align-items-center">
            <div className="column col-12 col-md-6 pe-0 pe-md-5 content">
              {bannerLogo &&
              <img
                src={bannerLogo}
                alt="AceLogo"
                className="banner-logo"
                />
              }
              <h1>{title}</h1>
              {subTitle && <div className="subtitle">{subTitle}</div>}
              {description && <p>{description}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

RdsHeroBanner.propTypes = {
  bannerImage: PropTypes.string,
  bannerLogo: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
};

export default RdsHeroBanner;
