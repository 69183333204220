import React from "react";
import BusinessLogos from "./BusinessLogos";
import FooterNavBar from "./FooterNavBar";
import { FooterContentData } from "../../constants/footer";
import styles from "./Footer.module.css";
import CopyRight from "./CopyRight/CopyRight";
import FloatingCircle from "../FloatingCircle"

const Footer = () => {
  const {
    businessLogoItems = [],
    footerNavItems = [],
    copyRightContent = {},
    privacyChoiceContent = {}
  } = FooterContentData;

  const footerTheme = "#cacaca";

  return (
    <div className={`${styles.footerContainer}`}>
      <FloatingCircle />
      <div className={`container`}>
        <div className={`row pt-4 pb-1 align-items-end`}>
          <div className={`col-sm-12 col-md-4`}>
            <BusinessLogos businessLogoItems={businessLogoItems} />
          </div>
          <div className={`col-sm-12 col-md-8`}>
            <FooterNavBar
              footerNavItems={footerNavItems}
              footerTheme={footerTheme}
            />
          </div>
        </div>
        <div className={`row`}>
          <div className="col">
            <hr className={styles.hr} />
          </div>
        </div>
        <div className={`row`}>
          <div className={`col-sm-12`}>
            <CopyRight
              copyRightContent={copyRightContent}
              privacyChoiceContent={privacyChoiceContent}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
