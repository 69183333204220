import React from "react";

import LinkWithArrow from "../../LinkWithArrow/LinkWithArrow";
import styles from "./ImageContentCard.module.css";
import { useIsMobile } from "../../../Utilities/Utility";

const ImageContentCard = (props) => {
  const isMobile = useIsMobile();
  const {
    image,
    header,
    title,
    titleDescription,
    linkText,
    linkColor,
    linkTarget = "_self",
    showArrow,
    altImgText,
    tabId = "",
    linkUrl,
    reverse,
    background,
    backgroundImage = false
  } = props;
  return (
    <div className={backgroundImage ? styles.bgImage : ""} style={{ background: !backgroundImage ? background : "" }}>
      <div key={tabId + header} id={tabId} className={`container py-4`}>
        <div className={`row ${reverse ? "flex-row-reverse" : ""}`}>
          <div className={`col-md-8 col-sm-12 align-self-center py-2 ${isMobile ? 'mb-4' : ''}`}>
            <h2 className={styles.heading}>{header}</h2>
            <h3 className={styles.subHeading}>{title}</h3>
            <p>{titleDescription}</p>
            <div className={isMobile ? 'd-flex justify-content-center my-4' : 'my-4'}>
              {showArrow && <LinkWithArrow
                linkText={linkText}
                linkColor={linkColor}
                linkTarget={linkTarget}
                showArrow={showArrow}
                linkUrl={linkUrl}
              />}
            </div>
          </div>
          <div className={`col-md-4 col-sm-12  ${reverse ? "" : "d-flex justify-content-center"}`}>
            <img src={image} alt={altImgText} width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageContentCard;
