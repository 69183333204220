import React from "react";

import Phone from "../../../../../assets/common/phone.svg";
import {
  formatPhoneNumber,
  getFranchiseLocalStorage,
} from "../../../../../Utilities/Utility";
import { ACE_HOME_SERVICES } from "../../../../../constants/header";
import styles from "./FranchiseInfo.module.css";
import { dataPush } from "../../../../../Utilities/GAnalytics";
import { PHONE_NUMBER_CLICK } from "../../../../../constants/gaTrack";

const FranchiseInfoPanel = (props) => {
  const { franchiseHeaderName, franchiseHeaderPhoneNumber } = props;

  const franchiseDetails = getFranchiseLocalStorage()?.franchiseDetails;
  const location = window.location.href;

  const handleWindowTracker = (e, franchiseDetails) => {
    e.stopPropagation();

    dataPush(
      PHONE_NUMBER_CLICK,
      franchiseHeaderName,
      franchiseDetails?.headerFranchiseAddress,
      location
    );
  };
  
  return (
    <div className={styles.fanchiseInfoContainer}>
      {franchiseHeaderName && (
        <>
          <div className={styles.heading}>{ACE_HOME_SERVICES}</div>
          <address className={styles.address}>{franchiseHeaderName}</address>
        </>
      )}
      {franchiseHeaderPhoneNumber && (
        <div className={styles.phoneContainer}>
          <img
            src={Phone}
            className={styles.image}
            alt="Phone"
            width="22"
            height="22"
          />
          <a
            href={`tel:${franchiseHeaderPhoneNumber}`}
            className={styles.text}
            onClick={(e) => handleWindowTracker(e, franchiseDetails)}
          >
            {formatPhoneNumber(franchiseHeaderPhoneNumber)}
          </a>
        </div>
      )}
    </div>
  );
};

export default FranchiseInfoPanel;
