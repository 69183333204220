import React from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../../Utilities/Utility";
import styles from "./BusinessLogos.module.css";

const BusinessLogos = ({ businessLogoItems = [] }) => {
  const isMobile = useIsMobile();
  return (
    <ul className={styles.logoContainer}>
      {businessLogoItems.map((logo, index) => {
        return (
          <li key={index}>
            <Link
              to={logo.url}
              target={logo.target ? logo.target : "_self"}
              rel={`${logo.target ? "noopener noreferrer" : ""}`}>
              <img
                src={logo.image}
                alt={logo.alt}
                width={isMobile ? "158px" : "100%"}
                height={isMobile ? "54px" : "58px"}
              />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default BusinessLogos;
