import AceHomeServicesLogo from "../assets/common/header/AceHomeServices.png";

export const ACE_HOME_SERVICES = "Ace Hardware Home Services";
export const HeaderContentData = {
  logo: {
    path: AceHomeServicesLogo,
    alt: ACE_HOME_SERVICES,
    width: "119",
    height: "94",
    url: "/",
  },
  headerMenu: [
    {
      menuText: "Home Services",
      redirectLink: "/",
      target: "_self",
      headerSubMenu: [
        {
          menuText: "Heating and Cooling",
          redirectLink: "/hvac",
          target: "_self",
        },
        {
          menuText: "Plumbing",
          redirectLink: "/plumbing",
          target: "_self",
        },
        {
          menuText: "Electrical",
          redirectLink: "/electrical",
          target: "_self",
        },
        {
          menuText: "Handyman",
          redirectLink: "https://acehandymanservices.com",
          target: "_blank",
        },
        {
          menuText: "Painting",
          redirectLink: "https://acehardwarepainting.com",
          target: "_blank",
        },
      ],
    },
    {
      menuText: "About Us",
      redirectLink: "/about-us",
      target: "_self",
    },
  ],
  headerCommonMenu: [
    {
      menuText: "Ace Hardware",
      redirectLink: "https://acehardware.com",
      target: "_blank",
    },
    {
      menuText: "Privacy Policy",
      redirectLink: "/privacy-policy",
      target: "_self",
    },
    {
      menuText: "Terms of Use",
      redirectLink: "/terms-and-conditions",
      target: "_self",
    },
    {
      menuText: "Sitemap",
      redirectLink: "/sitemap",
      target: "_self",
    },
  ],
};
