import React from "react";
import "../stylesheets/rds-image-copy-tray.css";
import Rdsbtn from "./rds-btn";
import { getTrayStyle, getTextStyle } from '../utility';
import { Markup } from 'interweave';

class RdsImageCopyTray extends React.Component {
  onClickHandler = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onSplitTray = (dimension, isMobile, method) => {
    let width = "50%";
    const gapWidth = 0; 
    if (dimension && !isMobile) {
      const splitDimension = dimension.split("-");
      if (method === "first" && splitDimension?.length > 0) {
        //width = `${splitDimension[0]}%`;   
        width = `calc(${splitDimension[0]}% - ${gapWidth}px)`;      
      }
      if (method === "second" && splitDimension?.length > 0) {
        //width = `${splitDimension[1]}%`;        
        width = `calc(${splitDimension[1]}% - ${gapWidth}px)`;     
      }
    } else if (isMobile) {
      width = "100%";
    }
    return width;
  };


  render() {
    const {
      anchorTagValue,
      backgroundColor,
      imageAlignment,
      trayDimension,
      isMobile,
      sectionHeading,
      heading,
      subHeading,
      summary,
      renderAction,
      imageUrl,
      imageAltText,
      cta,      
      franchiseData,
    } = this.props;

    const reverseTray = imageAlignment?.toUpperCase() === "LEFT";
    const trayFirst = this.onSplitTray(trayDimension, isMobile, "first");
    const traySecond = this.onSplitTray(trayDimension, isMobile, "second");
    const trayStyle = getTrayStyle(backgroundColor);
    let textStyle = getTextStyle(backgroundColor);
    const headerFranchisePhone = franchiseData?.franchiseDetails?.headerFranchisePhone;
    const renderRdsBtn = !headerFranchisePhone ? null : (
      <Rdsbtn
        tel={headerFranchisePhone}
        text={`CALL US TO LEARN MORE`}
        buttonClass="rds rds-btn primary phone-icon white-phone"
      />
    );

    return (
      <>
        <div className="rds-wrapper" style={trayStyle} id={anchorTagValue}>
          <div className="container">
            <div
              className={"rds-image-copy-tray"}
            >
              <div>
                <div
                  className={`rds-image-copy-tray-row ${reverseTray ? "rds-image-copy-tray-flex-row-reverse" : ""
                    }`}
                >
                  <div
                    className={`${isMobile ? "rds-image-copy-tray-mobile-spacing" : ""
                      }`}
                      style={{ width: trayFirst, padding: isMobile ? 0 : 30 }}
                  >
                    <div className="rds-image-copy-tray-coloumn-top-content">
                      {sectionHeading && (
                        <h2
                          className={`${isMobile ? "rds-image-copy-tray-center" : ""
                            } rds-image-copy-tray-allignment rds-section-heading`}
                          style={textStyle}
                        >
                          {sectionHeading}
                        </h2>
                      )}
                      {heading && (
                        <h3
                          className={`${isMobile ? "rds-image-copy-tray-center" : ""
                            } rds-image-copy-tray-allignment rds-heading`}
                          style={textStyle}
                        >
                          {heading}
                        </h3>
                      )}
                      {subHeading && (
                        <h4
                          className={`${isMobile ? "rds-image-copy-tray-center" : ""
                            } rds-image-copy-tray-allignment rds-subHeading`}
                          style={textStyle}
                        >
                          {subHeading}
                        </h4>
                      )}
                      {summary && (
                        <p className="rds-image-copy-tray-description rds-description" style={textStyle}>
                          <Markup content={summary} />
                          
                        </p>
                      )}
                      <div
                        className={`${isMobile ? "rds-image-copy-tray-center" : ""
                          } rds-image-copy-tray-btn`}
                      >
                        {renderAction}
                      </div>
                    {cta && cta.style === 'Phone_Number' && <div className="mb-4">{renderRdsBtn}</div>}
                    </div>
                  </div>
                  <div
                    className={`${reverseTray ? "" : "rds-image-copy-tray-reverse"
                      }`}
                      style={{ width: traySecond, padding: isMobile ? 0 : 30, ...(isMobile ? {} : { marginRight: 0 }) }}
                  >
                    <div className="rds-image-copy-tray-coloumn-bottom-content">
                      <img src={imageUrl} alt={imageAltText? imageAltText : sectionHeading} title={imageAltText? imageAltText : sectionHeading} width="100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RdsImageCopyTray;
