import React from "react";
import styles from "./FullTextBanner.module.css";

const FullTextBanner = ({contentData = {}}) => {
  return (
<div className={styles.whoWeAreBorder}>
<h1 className={styles.borderheading}>{contentData?.title}</h1>
<h2 className={styles.borderSubHeading}>{contentData?.whoWeAreBorderHeading}</h2>
<p className={styles.borderDescription}>{contentData?.whoWeAreBorderDescription}</p>
</div>
  );
};

export default FullTextBanner;
