import {
  faFacebook,
  faInstagram,
  faTwitter,
  faPinterest,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import AceLogo from "../assets/common/footer/AceNew.png";
import YourPrivacyChoices from "../assets/common/footer/your_privacy_choice.jpg";

const dateVal = new Date();
let yearVal = dateVal.getFullYear();

export const FooterContentData = {
  footerNavItems: [
    {
      name: "About Us",
      uri: "/about-us",
    },
    {
      name: "Ace Hardware",
      uri: "https://acehardware.com",
      target: "_blank"
    },    
    {
      name: "Privacy Policy",
      uri: "/privacy-policy",
    },
    {
      name: "Terms of Use",
      uri: "/terms-and-conditions",
    },
    {
      name: "Site Map",
      uri: "/sitemap",
    },
  ],
  businessLogoItems: [
    {
      name: "AceHardwareCorporate",
      url: "https://www.acehardwarehomeservices.com/",
      image: AceLogo,
      width: "100px",
      height: "100px",
      alt: "Ace Hardware logo",
      target: "_self"
    },
  ],
  socialMediaItems: {
    socialMediaText: "Connect with us",
    socialMediaLinks: [
      {
        name: "Facebook",
        uri: "https://www.facebook.com/AceHandymanSvcs/",
        icon: faFacebook,
        width: "15px",
        height: "16px",
      },
      {
        name: "Instagram",
        uri: "https://twitter.com/AHSFranchises",
        icon: faInstagram,
        width: "16px",
        height: "16px",
      },
      {
        name: "Twitter",
        uri: "https://www.acehandymanservices.com/",
        icon: faTwitter,
        width: "16px",
        height: "13px",
      },
      {
        name: "Pinterest",
        uri: "https://www.pinterest.com/acehandymanservices/",
        icon: faPinterest,
        width: "15px",
        height: "16px",
      },
      {
        name: "Youtube",
        uri: "https://www.youtube.com/user/HandymanMatters/",
        icon: faYoutube,
        width: "16px",
        height: "11px",
      },
      {
        name: "LinkedIn",
        uri: "https://www.linkedin.com/company/ace-handyman-services-inc/",
        icon: faLinkedin,
        width: "13px",
        height: "13px",
      },
    ],
  },
  copyRightContent: {
    copyRight: `© ${yearVal} Ace Hardware Home Services All Rights Reserved.`,
  },
  privacyChoiceContent: {
    privacyChoiceText: "Your Privacy Choices",
    privacyChoiceImg: YourPrivacyChoices,
    alt: "Your Privacy Choices",
    width: "29",
    height: "14",
    url: "https://privacyportal.onetrust.com/webform/08fcfb30-e571-44e6-a1de-eb62fe3d7b00/d31e7b56-6dcc-44e0-9a63-9189d1f7f5b3",
    target: "_blank"
  }
};
