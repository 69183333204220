import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import DropDown from "../DropDown";
import FaChevronDown from "../../SvgIconComponents/Arrows/FaChevronDown";
import styles from "./MenuItems.module.css";
import {
  getFranchiseDataFromLS,
  getFranchiseLocalStorage,
} from "../../../Utilities/Utility";
import { dataPush } from "../../../Utilities/GAnalytics";
import { JOB_APPLICATION_CLICK } from "../../../constants/gaTrack";

const MenuItems = ({ items, depthLevel, handleClose }) => {
  const location = useLocation();
  const ref = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const [hPos, setHpos] = useState({ rightPos: "auto", leftPos: "auto" });

  const recentFranchisePath = getFranchiseDataFromLS("recentFranchisePath");
  const franchiseDetails = getFranchiseLocalStorage()?.franchiseDetails;
  const carrerLocation = window.location.origin + recentFranchisePath;

  const handleWindowTracker = (e, franchiseDetails) => {
    e.stopPropagation();
    dataPush(
      JOB_APPLICATION_CLICK,
      franchiseDetails?.headerFranchiseName,
      franchiseDetails?.headerFranchiseAddress,
      carrerLocation
    );
  };

  const handleMouseOver = (e) => {
    e.preventDefault();

    const menuWidth = 320; //min-width set as 320px for menu dropdown
    const isNearRightEdge =
      window.innerWidth - ref.current.getBoundingClientRect().right;
    const isNearLeftEdge = ref.current.getBoundingClientRect().left;

    if (menuWidth > isNearRightEdge && depthLevel === 0) {
      setHpos((prevHpos) => ({ ...prevHpos, rightPos: "0" }));
    } else if (menuWidth > isNearRightEdge && depthLevel > 0) {
      setHpos((prevHpos) => ({ ...prevHpos, rightPos: "280px" }));
    } else if (menuWidth > isNearLeftEdge && depthLevel === 0) {
      setHpos((prevHpos) => ({ ...prevHpos, leftPos: "0" }));
    } else if (menuWidth > isNearLeftEdge && depthLevel > 0) {
      setHpos((prevHpos) => ({ ...prevHpos, leftPos: "280px" }));
    } else setHpos((prevHpos) => ({ ...prevHpos, leftPos: "0" }));
    setDropdown(true);
  };

  const handleMouseOut = (e) => {
    e.preventDefault();
    setDropdown(false);
  };

  return (
    <>
      <li className={styles.menuItems}>
        {items?.headerSubMenu && items?.headerSubMenu.length ? (
          <>
            <div
              aria-haspopup="menu"
              className={styles.dropDownItem}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleClose}
              aria-expanded={dropdown ? "true" : "false"}
              ref={ref}
            >
              <Link
                to={items.redirectLink}
                target={items.target}
                className={styles.dropDownItemParent}
              >
                {items.menuText}
              </Link>
              <FaChevronDown
                fillColor={"#858585"}
                rotate={
                  items?.headerSubMenu && depthLevel > 0
                    ? "-90"
                    : depthLevel === 0 && dropdown
                    ? "-180"
                    : "0"
                }
              />
              <DropDown
                subMenuItems={items?.headerSubMenu}
                dropdown={dropdown}
                depthLevel={depthLevel}
                position={{
                  horizontalPos: hPos,
                  topPos: depthLevel > 0 ? "0" : "44",
                }}
                handleClose={handleClose}
              />
            </div>
          </>
        ) : (
          <Link
            to={items.redirectLink}
            target={items.target}
            className={`${styles.menuItem} ${
              location.pathname === items.redirectLink ? styles.activeNav : ""
            }`}
            onClick={(e) => {
              handleClose();
              if (items?.menuText === "Careers") {
                handleWindowTracker(e, franchiseDetails);
              }
            }}
          >
            {items.menuText}
          </Link>
        )}
      </li>
    </>
  );
};

export default MenuItems;
