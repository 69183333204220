import React from "react";
import ImageContentCard from "../../Components/Cards/ImageContentCard/ImageContentCard";

const OurParentAndMission = ({ contentData = {} }) => {
  return (
    <>
      {contentData?.map((value, index) =>
        <ImageContentCard
          key={index}
          image={value.image}
          header={value.header}
          title={value.title}
          titleDescription={value.titleDescription}
          linkText={value.linkText}
          linkColor={value.linkColor}
          linkTarget={value.linkTarget}
          showArrow={value.showArrow}
          altImgText={value.altImgText}
          linkUrl={value.linkUrl}
          tabId={value.tabId}
          reverse={value?.division === 'right' ? true : false}
          background={value.background}
          backgroundImage={value?.backgroundImage}
        />
      )}
    </>
  );
};

export default OurParentAndMission;
