import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  scrollToSpecificPath,
  scrollToTop,
  getFranchiseDataFromLS,
  useIsMobile,
} from "../../Utilities/Utility";

const ScrollWrapper = ({ children }) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const [franchiseData, setFranchiseData] = useState(null);

  useEffect(() => {
    const dataFromLS = JSON.parse(getFranchiseDataFromLS("FRANCHISE_DETAILS"));
    setFranchiseData(dataFromLS);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const updatedFranchiseData = JSON.parse(
        getFranchiseDataFromLS("FRANCHISE_DETAILS")
      );
      setFranchiseData(updatedFranchiseData);
      setStickyTabPosition(updatedFranchiseData);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setStickyTabPosition = (franchiseData) => {
    const stickyTab = document.querySelector(".sticky-tab");
    if (stickyTab) {
      let topValue = "65px";
      if (
        franchiseData?.franchiseDetails?.headerFranchiseName &&
        franchiseData?.headerMenu &&
        franchiseData?.headerMenu.length > 0
      ) {
        topValue = isMobile ? "90px" : "169px";
      } else if (
        franchiseData?.franchiseDetails?.headerFranchiseName &&
        (!franchiseData?.headerMenu || franchiseData?.headerMenu.length === 0)
      ) {
        topValue = isMobile ? "129px" : "129px";
      }

      stickyTab.style.position = "sticky";
      stickyTab.style.top = topValue;
    }
  };

  useEffect(() => {
    // Scroll behavior based on location change
    if (location?.hash) {
      const pathId = location?.hash?.split("#");
      if (pathId.length > 1) {
        scrollToSpecificPath(pathId[1]);
      } else {
        scrollToTop();
      }
    } else {
      scrollToTop();
    }
  }, [location]);

  return children;
};

export default ScrollWrapper;
