import React from "react";
import styles from "./StepsCard.module.css";

const StepsCard = ({ cardData = {} }) => {
  return (
    <div className={`${styles.stepsCardContainer} mb-3`}>
      <img src={cardData?.whoWeAreImg} width="100%" alt="Who we are"/>
      <h2 className={`${styles.heading} my-2 mt-4 text-center`}>{cardData?.cardHeader}</h2>
      <h3 className={`text-center ${styles.stepCardTitle}`}>{cardData?.cardTitle}</h3>
      <p className={`text-center fs-6 my-2 ${styles.stepCardPara}`}>{cardData?.cardDesc}</p>
    </div>
  );
};

export default StepsCard;
