import React from "react";
import OverLayCard from "../Cards/OverLayCard/OverLayCard";
import styles from "./HeroBanner.module.css";
import { useIsMobile } from "../../Utilities/Utility";

const HeroBanner = (props) => {
  const isMobileDevice = useIsMobile();
  const { contentData } = props;

  return (
    <div
      className={`${styles.heroBanner} ${styles.heroBannerHeight}`}
      style={{
        backgroundImage: isMobileDevice
          ? `url(${contentData.bannerImageMob})`
          : `url(${contentData.bannerImage})`,
      }}
    >
      <div className="container-fluid p-0">
        <div className="col-sm-12 col-md-6">
          <OverLayCard cardData={contentData} />
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
