import React from "react";
import styles from "./WhyChooseUs.module.css";
import {useIsMobile} from "../../Utilities/Utility";
import CircleWithNumber from "../../Components/CircleWithNumber/CircleWithNumber";

const WhyChooseUs = ({ contentData = {} }) => {
    const { steps = [] } = contentData;
    const isMobile = useIsMobile();
    //bootstap class combined in one string
    const bsClasses = "col-12 d-flex justify-content-center";
    return (
        <>
            <div id={contentData?.tabId} className={` ${styles.chooseContainer}`}>
                <div className="container">
                    <div className="row pt-5">
                        <div className={`${bsClasses} mt-4`}>
                            <h2 className={`${isMobile ? 'text-center': ''} ${styles.heading}`}>{contentData.title}</h2>
                        </div>
                        <div className={`${bsClasses}`}>
                            <h3 className={`${isMobile ? 'text-center': ''} ${styles.subHeading}`}>{contentData.heading}</h3>
                        </div>
                        </div>
                        <div className="row mt-3 pb-5">
                            <div className="col-sm-12 col-md-6">
                                {steps?.map((value, index) => {
                                    return (<div className="d-flex gap-3 my-2" key={index}>
                                        <CircleWithNumber
                                        text={index + 1}
                                        width={'2.5rem'}
                                        height={'2.5rem'}
                                        textSize={'24px'} />
                                        <div>
                                            <h1 className={`${styles.contentTitle}`}>
                                                {value?.title}
                                            </h1>
                                            <p className={`${styles.contentBody}`}>
                                                {value?.content}
                                            </p>
                                        </div>
                                    </div>)
                                })}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <img src={contentData?.imageSrc} alt={contentData?.altImgText} width="100%" />
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
};

export default WhyChooseUs;
