import React from "react";
import { useSpring, animated } from "@react-spring/web";

import MenuItems from "../MenuItems";
import styles from "./DropDown.module.css";

const DropDown = ({ subMenuItems = [], dropdown, depthLevel, position, handleClose }) => {
  const {
    horizontalPos: { leftPos, rightPos },
    topPos,
  } = position;

  depthLevel = depthLevel + 1;

  const animation = useSpring({
    opacity: dropdown ? 1 : 0,
  });

  return (
    <animated.div
      className={`${styles.dropDownContainer} ${dropdown ? styles.show : ""}`}
      style={{
        top: `${topPos}px`,
        left: leftPos,
        right: rightPos,
        ...animation,
      }}
    >
      <ul className={styles.dropDownList}>
        {subMenuItems?.map((subMenu, index) => {
          return (
            <MenuItems items={subMenu} key={index} depthLevel={depthLevel} handleClose={handleClose} />
          );
        })}
      </ul>
    </animated.div>
  );
};

export default DropDown;
