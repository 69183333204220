import React, { useState } from "react";
import styles from "./dropDown.module.css";

const DropDown = ({ contentData, isMobileDevice, onTabChange }) => {
  const [current, setCurrent] = useState(
    contentData?.length ? contentData[0].value : ""
  );

   const handleTabChange = (e) => {
     onTabChange(e.target.value);
     setCurrent(e.target.value);
   };

  return (

    <div className={`${styles.dropDownContainer}`}>
      <select
        className={`${styles.dropDownSelect}`}
        onChange={handleTabChange}
      >
        {contentData?.map((it, i) => (
          <option
            className={`${
              current === it?.value && styles.dropDownSelectOption
            }`}
            key={i}
            value={it?.value}
          >
            {it?.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDown;
