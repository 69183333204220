import { v4 as uuid } from "uuid";
import HandyManServices from "../assets/services/desktop/more_service_handyman.svg";
import PaintingServices from "../assets/services/desktop/more_service_painting.png";
import ElecticalIcon from "../assets/services/desktop/SimpleIcons_ElectricalServices.png";
import HeatingAndCoolingIcon from "../assets/services/desktop/SimpleIcons_HeatingCoolingServices.png";
import PlumbingIcon from "../assets/services/desktop/SimpleIcons_PlumbingServices.png";

import HandyManServicesMobile from "../assets/services/mobile/more_service_handyman_mobile.svg";
import PaintingServicesMobile from "../assets/services/mobile/more_service_painting_mobile.png";
import ElecticalIcon_Mobile from "../assets/services/mobile/SimpleIcons_ElectricalServices.svg";
import HeatingAndCoolingIcon_Mobile from "../assets/services/mobile/SimpleIcons_HeatingCoolingServices.svg";
import PlumbingIcon_Mobile from "../assets/services/mobile/SimpleIcons_PlumbingServices.svg";

import AllPhaseIcon from "../assets/services/desktop/uic_all_phase_electric.png";
import ATotalPlumbingIcon from "../assets/services/desktop/uic_a_total_plumbing.png";
import MoncriefIcon from "../assets/services/desktop/uic_moncrief.png";
import DickHillIcon from "../assets/services/desktop/uic_dick_hill.png";
import ClaysClimateControlIcon from "../assets/services/desktop/uic_clays.png";
import UniqueIndoorComfortIcon from "../assets/services/desktop/uic_unique_comfort.png";
import CanellaIcon from "../assets/services/desktop/uic_canella.png";
import RyeIcon from "../assets/services/desktop/uic_rye.png";
import DetmerIcon from "../assets/services/desktop/uic_detmer.png";
import WerleyIcon from "../assets/services/desktop/uic_werley.png";
import CarolinaCustomIcon from "../assets/services/desktop/uic_carolina.png";
import JacksonPlumbingIcon from "../assets/services/desktop/uic_jackson.png";
import AcePlumbingIcon from "../assets/services/desktop/uic_ace_plumbing_okc.png";
import LegacyPlumbingIcon from "../assets/services/desktop/uic_legacy.png";
import ParrishServicesIcon from "../assets/services/desktop/uic_parrish.png";
import DesktopServiceHeroImg from "../assets/services/desktop/service_locations_hero.png";
import MobileServiceHeroImg from "../assets/services/mobile/service_locations_hero_mobile.png";

//importing Desktop icons for our services
import Desktop_ElectricalServicesIcon from "../assets/services/desktop/SimpleIcons_ElectricalServices.png";
import Desktop_HeatingCoolingServicesIcon from "../assets/services/desktop/SimpleIcons_HeatingCoolingServices.png";
import Desktop_PlumbingServicesIcon from "../assets/services/desktop/SimpleIcons_PlumbingServices.png";
import Desktop_PaintingServicesIcon from "../assets/services/desktop/SimpleIcons_PaintingServices.svg";
import Desktop_HandymanServicesIcon from "../assets/services/desktop/SimpleIcons_HandymanServices.svg";

//importing Mobile icons for our services
import Mobile_ElectricalServicesIcon from "../assets/services/mobile/SimpleIcons_ElectricalServices.svg";
import Mobile_HeatingCoolingServicesIcon from "../assets/services/mobile/SimpleIcons_HeatingCoolingServices.svg";
import Mobile_PlumbingServicesIcon from "../assets/services/mobile/SimpleIcons_PlumbingServices.svg";
import Mobile_PaintingServicesIcon from "../assets/services/mobile/SimpleIcons_PaintingServices.svg";
import Mobile_HandymanServicesIcon from "../assets/services/mobile/SimpleIcons_HandymanServices.svg";

const ELECTRICAL_SERVICES = "Electrical Services";
const HEATING_COOLING_SERVICES = "Heating & Cooling Services";
const PLUMBING_SERVICES = "Plumbing Services";
const PAINTING_SERVICES = "Painting Services";
const HANDYMAN_SERVICES = "Handyman Services";

const ELECTRICAL_SERVICES_URL = "/service-locations#electrical";
const HEATING_COOLING_SERVICES_URL = "/service-locations#heating-cooling";
const PLUMBING_SERVICES_URL = "/service-locations#plumbing";
const PAINTING_SERVICES_URL = "https://www.acehardwarepainting.com/";
const HANDYMAN_SERVICES_URL = "https://www.acehandymanservices.com/";
const LINK_COLOR = "#d5202e";

export const VIEW_SERVICES = "View Services";
export const LEARN_MORE = "Learn More";
export const GETESTIMATE_COMPANY =
  "Ace Hardware Plumbing Services North Oklahoma City";
export const SCHEDULE = "Schedule Now";
export const CALL = "Call";
export const ESTIMATE = "Estimate Now";

export const OurServicesData = {
  OurServicesSection: {
    title: "What we do",
    mainHeading: "Our Services",
    viewServices: "View Services",
    learnMore: "Learn More",
    showArrow: true,
    linkText: "Go to Service Locations",
    linkUrl: "/service-locations",
    linkColor: "#d5202e",
    tabId: "what-we-do",
  },
  ListOfOurServices: [
    {
      image: Desktop_ElectricalServicesIcon,
      imageMobile: Mobile_ElectricalServicesIcon,
      serviceArea: ELECTRICAL_SERVICES,
      displayLearnMoreLink: false,
      learnMore: "",
      ViewServices: process.env.REACT_APP_ACE_ELECTRICALSERVICES_URL,
      linkColor: LINK_COLOR,
      isClickableCard: true,
    },
    {
      image: Desktop_HeatingCoolingServicesIcon,
      imageMobile: Mobile_HeatingCoolingServicesIcon,
      serviceArea: HEATING_COOLING_SERVICES,
      displayLearnMoreLink: false,
      learnMore: "",
      ViewServices: process.env.REACT_APP_ACE_HVAC_SERVICES_URL,
      linkColor: LINK_COLOR,
      isClickableCard: true,
    },
    {
      image: Desktop_PlumbingServicesIcon,
      imageMobile: Mobile_PlumbingServicesIcon,
      serviceArea: PLUMBING_SERVICES,
      displayLearnMoreLink: false,
      learnMore: "",
      ViewServices: process.env.REACT_APP_ACE_PLUMBING_SERVICES_URL,
      linkColor: LINK_COLOR,
      isClickableCard: true,
    },
    {
      image: Desktop_PaintingServicesIcon,
      imageMobile: Mobile_PaintingServicesIcon,
      serviceArea: PAINTING_SERVICES,
      displayLearnMoreLink: true,
      learnMore: process.env.REACT_APP_ACE_PAINTINGSERVICES_URL,
      ViewServices: "",
      linkColor: LINK_COLOR,
      isClickableCard: true,
    },
    {
      image: Desktop_HandymanServicesIcon,
      imageMobile: Mobile_HandymanServicesIcon,
      serviceArea: HANDYMAN_SERVICES,
      displayLearnMoreLink: true,
      learnMore: process.env.REACT_APP_ACE_HANDYMANSERVICES_URL,
      ViewServices: "",
      linkColor: LINK_COLOR,
      isClickableCard: true,
    },
  ],
};

export const ServiceBannerData = {
  title: "Service Locations",
  description:
    "Ace Hardware Home Services is dedicated to serving the community. Our licensed technicians bring deep industry knowledge and technical proficiency to your home, with the helpful service you expect from Ace.",
  bannerImage: DesktopServiceHeroImg,
  bannerImageMob: MobileServiceHeroImg,
};

export const ServiceTabData = [
  { label: "Electrical", value: "electrical" },
  { label: "Heating & Cooling", value: "heating-cooling" },
  { label: "Plumbing", value: "plumbing" },
];

export const ServiceLocationItems = [
  {
    id: uuid(),
    serviceArea: "Electrical Services",
    tabId: "electrical",
    logo: ElecticalIcon,
    logoMobile: ElecticalIcon_Mobile,
    serviceAreaStateDetails: [
      {
        detailId: uuid(),
        stateName: "Alabama",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Decatur",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Jackson Plumbing, Heating & Cooling",
                address1: "4504 HWY 31 South",
                address2: "Decatur, AL 35603",
                phone: "(256) 530-0624",
                logo: JacksonPlumbingIcon,
                logoAlt:
                  "Logo for Jackson Plumbing, Heating & Cooling, an Ace Hardware company.",
                businessLink:
                  "https://www.jacksonplumbingheatingandcooling.com/",
                scheduleLink:
                  "https://www.jacksonplumbingheatingandcooling.com/contact-us/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Pennsylvania",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Philadelphia",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "All Phase Electric Co.",
                address1: "9015 W Chester Pike",
                address2: "Upper Darby, PA 19082",
                phone: "(484) 455-4490",
                logo: AllPhaseIcon,
                logoAlt:
                  "Logo for All Phase Electric Co., an Ace Hardware company.",
                businessLink: "https://allphaseelectricco.com/",
                scheduleLink: "https://allphaseelectricco.com/contact/",
              },
              {
                companyDetailId: uuid(),
                name: "Unique Indoor Comfort Heating & Cooling",
                address1: "780 5th Ave Suite 140",
                address2: "King of Prussia, PA 19406",
                phone: "(610) 989-2620",
                logo: UniqueIndoorComfortIcon,
                logoAlt:
                  "Logo for Unique Indoor Comfort Heating & Cooling, an Ace Hardware company.",
                businessLink: "https://www.uniqueheatingandcooling.com/",
                scheduleLink:
                  "https://www.uniqueheatingandcooling.com/#schedule-now",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Virginia",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Northern Virginia",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Parrish Services",
                address1: "7865 Coppermine Drive",
                address2: "Manassas, VA 20109",
                phone: "(571) 286-5469",
                logo: ParrishServicesIcon,
                logoAlt: "Logo for Parrish Services, an Ace Hardware company.",
                businessLink: "https://www.parrishservices.com/",
                scheduleLink:
                  "https://www.parrishservices.com/contact/schedule-appointment/",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: uuid(),
    serviceArea: "Heating & Cooling Services",
    tabId: "heating-cooling",
    logo: HeatingAndCoolingIcon,
    logoMobile: HeatingAndCoolingIcon_Mobile,
    serviceAreaStateDetails: [
      {
        detailId: uuid(),
        stateName: "Alabama",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Decatur",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Jackson Plumbing, Heating & Cooling",
                address1: "4504 HWY 31 South",
                address2: "Decatur, AL 35603",
                phone: "(256) 530-0624",
                logo: JacksonPlumbingIcon,
                logoAlt:
                  "Logo for Jackson Plumbing, Heating & Cooling, an Ace Hardware company.",
                businessLink:
                  "https://www.jacksonplumbingheatingandcooling.com/",
                scheduleLink:
                  "https://www.jacksonplumbingheatingandcooling.com/contact-us/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Georgia",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Atlanta",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Moncrief Heating & Air Conditioning",
                address1: "935 Chattahoochee Ave NW Ste A",
                address2: "Atlanta, GA 30318-3718",
                phone: "(877) 592-2300",
                logo: MoncriefIcon,
                logoAlt:
                  "Logo for Moncrief Heating and Air Conditioning, an Ace Hardware company.",
                businessLink: "https://www.moncriefair.com/",
                scheduleLink: "https://www.moncriefair.com/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Indiana",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Richmond",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Dick Hill and Son",
                address1: "693 Indiana Ave.",
                address2: "Richmond, IN 47374",
                phone: "(765) 233-6451",
                logo: DickHillIcon,
                logoAlt: "Logo for Dick Hill and Son, an Ace Hardware company.",
                businessLink: "https://dickhillandsonheatingandcooling.com/",
                scheduleLink:
                  "https://dickhillandsonheatingandcooling.com/request-quote/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "New Jersey",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "South Jersey",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Clay’s Climate Control",
                address1: "501 W. Patcong Ave.",
                address2: "Linwood, NJ 08221",
                phone: "(609) 431-5036",
                logo: ClaysClimateControlIcon,
                logoAlt:
                  "Logo for Clay’s Climate Control, an Ace Hardware company.",
                businessLink: "https://claysclimatecontrol.com/",
                scheduleLink:
                  "https://claysclimatecontrol.com/?se_campaign=AceHardwareHomeServices&se_action=eyJ0eXBlIjoic2Utc2hvdy1tb2RhbCIsIm9wdGlvbnMiOnsicm9vbUtleSI6ImdlbmVyYWwifX0%3D",
              },
              {
                companyDetailId: uuid(),
                name: "Unique Indoor Comfort Heating & Cooling",
                address1: "309 Fellowship Road, Suite 200",
                address2: "Mount Laurel NJ 08054",
                phone: "(610) 989-2620",
                logo: UniqueIndoorComfortIcon,
                logoAlt:
                  "Logo for Unique Indoor Comfort Heating & Cooling, an Ace Hardware company.",
                businessLink: "https://www.uniqueheatingandcooling.com/",
                scheduleLink:
                  "https://www.uniqueheatingandcooling.com/#schedule-now",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "North Carolina",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Hickory",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Canella Heating & Air Conditioning, Inc.",
                address1: "1204 1st St W",
                address2: "Hickory, NC 28601",
                phone: "(828) 490-7034",
                logo: CanellaIcon,
                logoAlt:
                  "Logo for Canella Heating & Air Conditioning, Inc., an Ace Hardware company.",
                businessLink: "https://www.canellaheatingandair.com/",
                scheduleLink:
                  "https://www.canellaheatingandair.com/contact/send-message",
              },
            ],
          },
          {
            cityDetailId: uuid(),
            cityName: "Lincolnton",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Rye Heating and Air Conditioning",
                address1: "3032 Gastonia Hwy,",
                address2: "Lincolnton, NC 28092",
                phone: "(704) 630-7399",
                logo: RyeIcon,
                logoAlt:
                  "Logo for Rye Heating and Air Conditioning, an Ace Hardware company.",
                businessLink: "https://www.ryeheatingandair.com/",
                scheduleLink:
                  "https://www.ryeheatingandair.com/contact-us/schedule-service",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Ohio",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Dayton",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Detmer and Sons, Inc.",
                address1: "1170 Channingway Drive",
                address2: "Dayton, OH 45324",
                phone: "(937) 249-2952",
                logo: DetmerIcon,
                logoAlt:
                  "Logo for Detmer and Sons, Inc., an Ace Hardware company.",
                businessLink: "https://www.detmersons.com/",
                scheduleLink: "https://www.detmersons.com/",
              },
            ],
          },
          {
            cityDetailId: uuid(),
            cityName: "New Lebanon",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Dick Hill and Son",
                address1: "38 W Main Street",
                address2: "New Lebanon, OH 45345",
                phone: "(765) 233-6451",
                logo: DickHillIcon,
                logoAlt: "Logo for Dick Hill and Son, an Ace Hardware company.",
                businessLink: "https://dickhillandsonheatingandcooling.com/",
                scheduleLink:
                  "https://dickhillandsonheatingandcooling.com/request-quote/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Pennsylvania",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Allentown",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Werley Heating & Air Conditioning Co.",
                address1: "1820 W Fairview St.,",
                address2: "Allentown, PA 18104",
                phone: "(610) 484-4128",
                logo: WerleyIcon,
                logoAlt:
                  "Logo for Werley Heating & Air Conditioning Co., an Ace Hardware company.",
                businessLink: "https://www.callwerley.com/",
                scheduleLink: "https://www.callwerley.com/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "South Carolina",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Charleston",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Carolina Custom Air",
                address1: "4993 Hwy 17 N",
                address2: "Awendaw, SC 29429",
                phone: "(843) 994-3729",
                logo: CarolinaCustomIcon,
                logoAlt:
                  "Logo for Carolina Custom Air, an Ace Hardware company.",
                businessLink: "https://carolinacustomair.com/",
                scheduleLink:
                  "https://carolinacustomair.com/schedule-appointment",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Virginia",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Northern Virginia",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Parrish Services",
                address1: "7865 Coppermine Drive",
                address2: "Manassas, VA 20109",
                phone: "(571) 286-5469",
                logo: ParrishServicesIcon,
                logoAlt: "Logo for Parrish Services, an Ace Hardware company.",
                businessLink: "https://www.parrishservices.com/",
                scheduleLink:
                  "https://www.parrishservices.com/contact/schedule-appointment/",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: uuid(),
    serviceArea: "Plumbing Services",
    tabId: "plumbing",
    logo: PlumbingIcon,
    logoMobile: PlumbingIcon_Mobile,
    serviceAreaStateDetails: [
      {
        detailId: uuid(),
        stateName: "Alabama",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Decatur",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Jackson Plumbing, Heating & Cooling",
                address1: "4504 HWY 31 South",
                address2: "Decatur, AL 35603",
                phone: "(256) 530-0624",
                logo: JacksonPlumbingIcon,
                logoAlt:
                  "Logo for Jackson Plumbing, Heating & Cooling, an Ace Hardware company.",
                businessLink:
                  "https://www.jacksonplumbingheatingandcooling.com/",
                scheduleLink:
                  "https://www.jacksonplumbingheatingandcooling.com/contact-us/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Georgia",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Atlanta",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "A-Total Plumbing",
                address1: "10 River Ct SW",
                address2: "Cartersville, GA 30120",
                phone: "(833) 891-5424",
                logo: ATotalPlumbingIcon,
                logoAlt: "Logo for A-Total Plumbing, an Ace Hardware company.",
                businessLink: "https://www.a-totalplumbing.com/",
                scheduleLink:
                  "https://www.a-totalplumbing.com/?se_action=eyJ0eXBlIjoic2Utc2hvdy1tb2RhbCIsIm9wdGlvbnMiOnsicm9vbUtleSI6ImdlbmVyYWwifX0%3D",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Oklahoma",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "North Oklahoma City",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Ace Hardware Plumbing Services North Oklahoma City",
                address1:
                  "Servicing The Village, Nichols Hills, Edmond, Oklahoma City, and More.",
                address2: "",
                phone: "(405) 901-9782",
                logo: AcePlumbingIcon,
                logoAlt:
                  "Logo for Ace Hardware Plumbing Services North Oklahoma City.",
                businessLink:
                  "https://acehardwareplumbing.com/offices/north-oklahoma-city",
                scheduleLink:
                  "https://acehardwareplumbing.com/offices/north-oklahoma-city#GetEstimateLink",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Texas",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "North Dallas, Collin & Denton Counties",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Legacy Plumbing",
                address1: "1101 E Eldorado Pkwy",
                address2: "Little Elm, TX 75068",
                phone: "(469) 294-3006",
                logo: LegacyPlumbingIcon,
                logoAlt: "Logo for Legacy Plumbing, an Ace Hardware company.",
                businessLink: "https://legacyplumbing.net/",
                scheduleLink:
                  "https://legacyplumbing.net/contact/schedule-service/",
              },
            ],
          },
        ],
      },
      {
        detailId: uuid(),
        stateName: "Virginia",
        serviceAreaCityDetails: [
          {
            cityDetailId: uuid(),
            cityName: "Northern Virginia",
            companyDetails: [
              {
                companyDetailId: uuid(),
                name: "Parrish Services",
                address1: "7865 Coppermine Drive",
                address2: "Manassas, VA 20109",
                phone: "(571) 286-5469",
                logo: ParrishServicesIcon,
                logoAlt: "Logo for Parrish Services, an Ace Hardware company.",
                businessLink: "https://www.parrishservices.com/",
                scheduleLink:
                  "https://www.parrishservices.com/contact/schedule-appointment/",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const MoreServiceData = {
  label: "More Services",
  title: "More Ace Hardware Home Services",
  serviceDetails: [
    {
      serviceTitle: "Handyman services",
      imageSrc: HandyManServices,
      imageSrcMobile: HandyManServicesMobile,
      redirectLink: "https://www.acehandymanservices.com",
    },
    {
      serviceTitle: "Painting services",
      imageSrc: PaintingServices,
      imageSrcMobile: PaintingServicesMobile,
      redirectLink: "https://www.acehardwarepainting.com",
    },
  ],
};
