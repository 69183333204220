import React from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";

import FaArrowLeft from "../../../../SvgIconComponents/Arrows/FaArrowLeft";
import CloseButton from "../../../../SvgIconComponents/CloseButton/CloseButton";
import styles from "./LogoPanel.module.css";

const LogoPanel = (props) => {
  const {
    handleClick,
    handleClose,
    handleBackArrow,
    isSubmenuActive,
    logo,
  } = props;

  const backBtnStyles = useSpring({
    from: {
      opacity: 0,
    },
    opacity: 1,
    config: { mass: 1, tension: 35, friction: 10 },
  });

  return (
    <div className={`row align-items-center ${styles.logoPanel}`}>
      <div className="col">
        <animated.button
          className={`${isSubmenuActive ? "d-flex" : "d-none"} ${
            styles.bgTransparent
          } ${styles.backButton} rounded-circle border-0`}
          onClick={handleBackArrow}
          style={{ ...backBtnStyles }}
        >
          <FaArrowLeft />
        </animated.button>
      </div>
      <div className="col">
        <Link to={logo.url} onClick={handleClose}>
          <img
            src={logo.path}
            alt={logo.alt}
            className={styles.image}
            width="100"
            height="42"
          />
        </Link>
      </div>
      <div className="col d-flex justify-content-end">
        <button
          className={`rounded-circle border-0 ${styles.bgTransparent}`}
          onClick={handleClick}
        >
          <CloseButton />
        </button>
      </div>
    </div>
  );
};

export default LogoPanel;
