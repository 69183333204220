import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchFranchiseMaintenance } from "../../redux-toolkit/slice/franchiseMaintenance/franchiseMaintenanceSlice";
import DynamicTrays from "../../Components/DynamicTrays";
import ErrorPage from "../../Components/ErrorPage";
import NetworkManager from "../../redux-toolkit/networkManager/networkConfig";
import { getFranchiseLocalStorage } from "../../Utilities/Utility";
import useMetaDetails from "../../hooks/useMetaDetails";
import useDisplayMsgForExternalFranchise from "../../hooks/useDisplayMsgForExternalFranchise";

const FranchiseMaintenance = () => {
  const { franchiseName } = useParams();
  const { data, isError, statusCode } = useSelector(
    (state) => state.franchiseMaintenance
  );
  const externalFranchiseMessage =
    useDisplayMsgForExternalFranchise("officesource");
  const dispatch = useDispatch();
  const [isPageContainsError, setIsPageContainsError] = useState(false);
  const [formSubmissionError, setFormSubmissionError] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const navigate = useNavigate();

  useMetaDetails(data);

  useEffect(() => {
    const payLoad = `?FranchiseName=${franchiseName}`;
    dispatch(fetchFranchiseMaintenance(payLoad));
  }, [franchiseName]);

  useEffect(() => {
    setIsPageContainsError(isError);
  }, [isError, statusCode]);

  useEffect(() => {
    if (formSuccess) {
      const pathNameList = window.location.pathname.split("/");
      const confirmationPageData = getConfirmationPageData();
      navigate(
        `/${pathNameList[1]}/${pathNameList[2]}/maintenance-confirmation`,
        { state: { data: confirmationPageData } }
      );
    }
  }, [formSuccess]);

  const getConfirmationPageData = () => {
    let confirmationData = data?.trays?.filter(
      (tray) => tray.type === "MaintenanceContactTray"
    )[0]?.maintenanceEnrollConfirmationForm;
    return confirmationData;
  };

  const handleMaintenanceContactSubmit = (userData) => {
    const franchiseLocalStorageData = getFranchiseLocalStorage();
    const franchiseData = {
      name:
        franchiseLocalStorageData?.franchiseDetails?.headerFranchiseName || "",
      phoneNumber:
        franchiseLocalStorageData?.franchiseDetails?.headerFranchisePhone || "",
      emailAddress:
        franchiseLocalStorageData?.franchiseDetails?.headerFranchiseEmail || "",
    };

    const formData = {
      formName: "MAINTENANCE",
      userData,
      franchiseData,
    };

    NetworkManager.franchiseSendEmail(formData).then((response) => {
      if (response.status === 200) {
        setFormSuccess(true);
      } else {
        setFormSubmissionError(true);
        setIsPageContainsError(true);
      }
    });
  };

  return (
    <>
      {data?.trays?.map((tray, index) => {
        return (
          <>
            {tray.type === "MaintenanceContactTray"
              ? !formSuccess && (
                  <DynamicTrays
                    {...tray}
                    key={index}
                    onFormSubmission={handleMaintenanceContactSubmit}
                    formSubmissionError={formSubmissionError}
                  />
                )
              : null}
            {tray.type !== "MaintenanceContactTray" && (
              <DynamicTrays
                {...tray}
                key={index}
                externalFranchiseMessage={externalFranchiseMessage}
              />
            )}
          </>
        );
      })}

      {isPageContainsError && <ErrorPage statusCode={statusCode} />}
    </>
  );
};

export default FranchiseMaintenance;
