import React, { useState, useEffect, useRef } from "react";
import { handleTabChange } from "../utility";
import "../stylesheets/rds-tab-switcher.css";

const RdsTabSwitcher = ({ isMobile, contentData , isLastVisitedFranchiseRibbonActive }) => {
  const hashPath = window.location.hash ? window.location.hash.split("#")[1] : "";

  const [activeItem, setActiveItem] = useState(
    hashPath ? hashPath : contentData?.length ? contentData[0].value : ""
  );

  const sections = useRef([]);

  useEffect(() => {
    sections.current = document.querySelectorAll(".rds-wrapper");

    const handleScroll = () => {
      const pageYOffset = window.scrollY;
      let newActiveItem = activeItem;
      sections.current.forEach((section) => {
        const sectionOffsetTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
  
        if (pageYOffset >= sectionOffsetTop - window.innerHeight / 2 && pageYOffset < sectionOffsetTop + sectionHeight - window.innerHeight / 2) {
          newActiveItem = section.id;
        } else if (pageYOffset < sections.current[0].offsetTop) {
          newActiveItem = sections.current[0].id; 
        }
      });
  
      setActiveItem(newActiveItem);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeItem]);

  return (
    <>
      <div className="sticky-tab">
        {isMobile ? (
          <div className="dropDownContainer">
            <select
              className="dropDownSelect"
              onChange={(e) =>
                handleTabChange(e.target.value, setActiveItem, isLastVisitedFranchiseRibbonActive)
              }
              value={activeItem}
            >
              {contentData?.map((it, i) => (
                <option
                  className={activeItem === it.value ? "dropDownSelectOption" : ""}
                  key={i}
                  value={it?.value}
                >
                  {it?.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="tabSwitchContainer">
            <div className="tabSwitchContent">
              <div className="container">
                <div className="tabItemRow">
                  {contentData?.map((it, i) => (
                    <div
                      key={i}
                      id={`${it.value}-tab`}
                      className="tabItems"
                      onClick={() =>
                        handleTabChange(it.value, setActiveItem,isLastVisitedFranchiseRibbonActive)
                      }
                    >
                      <span className={activeItem === it.value ? "tabItemsSelected" : ""}>
                        {it?.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RdsTabSwitcher;
